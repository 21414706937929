import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { noop } from 'rxjs';
import { Video } from '../../models/video';

@Component({
  selector: 'app-kin-video',
  templateUrl: './kin-video.component.html',
  styleUrls: ['./kin-video.component.scss']
})
export class KinVideoComponent implements OnInit, OnChanges {
  @Input() video!: Video;
  private url: string = 'https://player.vimeo.com/video/';
  videoUrl: SafeResourceUrl | undefined = undefined;
  constructor(
    private sanitizer: DomSanitizer
  ) { }
  getVideoUrl() {
    const id = this.video.vimId;
    const url = this.url + id;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.video ? (this.videoUrl = this.getVideoUrl()) : noop();
    
  }
}