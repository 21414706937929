<div class="homepage">
    <!-- <app-kin-training-navbar></app-kin-training-navbar> -->
    <div class="homepage-hero">
        <div class="homepage-hero-carousel">
            <app-carousel></app-carousel>
        </div>
    </div>
    <div class="homepage-top">
        <h3 class="homepage-top-header">Simple, straight forward pricing</h3>
        <h5 class="homepage-top-subheader">When it comes to cost, no one likes surprises. You know what you're going to pay, in-advance, without any complicated licensing structures to navigate.</h5>
        <app-pricing></app-pricing>
    </div>
    <!-- <div class="homepage-spacer"></div> -->
    <!-- <div class="homepage-lower">
        <div class="homepage-lower-left">
            <div class="homepage-teaser">
                <div class="homepage-teaser-left">
                    <div class="homepage-teaser-left-text">
                        Intuitive, familiar, and easy to use
                    </div>
                </div>
                <div class="homepage-teaser-right">
                    <img src="../../../assets/images/app-images/course-page.png" alt="Course Sample" class="homepage-teaser-right-image">
                </div>
            </div>
        </div>
        <div class="homepage-lower-right">
            <div class="homepage-teaser">
                <div class="homepage-teaser-left">
                    <div class="homepage-teaser-left-text">
                        Intuitive, familiar, and easy to use
                    </div>
                </div>
                <div class="homepage-teaser-right">
                    <img src="../../../assets/images/app-images/course-page.png" alt="Course Sample" class="homepage-teaser-right-image">
                </div>
            </div>
        </div>
    </div> -->
    <div class="homepage-bottom">
        <div class="homepage-bottom-left">
            <div class="homepage-bottom-left-text">
                Intuitive, familiar, and easy to use <br><br>
                As it should be
            </div>
        </div>
        <div class="homepage-bottom-right">
            <img *inView src="../../../assets/images/app-images/course-page.png" alt="Course Sample" class="homepage-bottom-right-image">
        </div>
    </div>

    <div class="homepage-nasba">
        <nasba></nasba>
    </div>
    <div class="homepage-placeholder"></div>
</div>
<app-kin-training-footer></app-kin-training-footer>
