import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  form!: FormGroup;
  constructor(
    public authService: AuthService,
    private userService: UserService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.form = new FormGroup({
      username: new FormControl('', [
        Validators.required
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
  }
  async login(form: FormGroup) {
    const ret = await this.authService.login(form.value.username, form.value.password);
    this.authService.setSession(ret);
    this.verifyToken();
  }
  async verifyToken() {
    const status:any = await this.authService.verifyToken();
    console.log(status);
    this.getUser(status.status);
  }
  async getUser(id: string) {
    let user: User = await this.userService.getUserAcct(id);
    console.log(user);
    this.setSession(user);
    this.userService.user$ = this.userService.getUserAcctObs(id);
  }
  setSession(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.sharedService.darkMode.next(user.settings.darkMode);
    this.router.navigate(['/']);
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
