import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { noop, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-kin-training-navbar',
  templateUrl: './kin-training-navbar.component.html',
  styleUrls: ['./kin-training-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KinTrainingNavbarComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  navDarkMode!: boolean;
  downloadUrl!: Observable<string>;
  constructor(
    private sharedServices: SharedService,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private userService: UserService
  ) { }
  async verifyToken() {
    let status = await this.authService.verifyToken();
    if (status.status === 0) {
      this.logout();
    }
  }
  get user () {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }
  async getUrl(userId: string) {
    this.downloadUrl = this.storageService.getUrlObs('user', userId);
  }
  getSettings() {
    this.sharedServices.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((e: boolean) => {
      this.navDarkMode = e;
    }, err => console.log(err), () => console.log('complete'));
  }
  switchDarkMode() {
    this.sharedServices.toggleDarkMode(!this.navDarkMode);
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
  asdf () {
    let user = this.user;
    if (user) {
      this.getUrl(user.userId);
    }
  }
  ngOnInit(): void {
    this.verifyToken();
    this.getSettings();
    // this.getUrl(this.user.userId);
    this.asdf();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
