import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { Video } from '../../models/video';

@Component({
  selector: 'app-video-description',
  templateUrl: './video-description.component.html',
  styleUrls: ['./video-description.component.scss']
})
export class VideoDescriptionComponent implements OnInit {
  @Input() currentVideo!: Video;
  @Input() darkMode!: Boolean;
  @Output() instructor: any = new EventEmitter();
  instructorImage!: string;
  downloadUrl!: Observable<string>;
  constructor(
    private storageService: StorageService
  ) { }
  sendInstructor() {
    this.instructor.emit(this.currentVideo.instructor);
  }
  async getUrl(userId: string) {
    this.downloadUrl = this.storageService.getUrlObs('user', userId);
  }
  ngOnInit(): void {
    this.getUrl(this.currentVideo.instructor.instructorId)
  }

}
