import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  darkMode = new BehaviorSubject(false);
  platforms: string[] = ['cognos analytics', 'costpoint', 'kinetek performance planning'];
  constructor() {
    this.getUserFromStorage();
  }
  getUserFromStorage() {
    let user = localStorage.getItem('user');
    if (user) {
      this.darkMode.next(
        (JSON.parse(user)).settings.darkMode
      );
    }
  }
  toggleDarkMode(a: boolean) {
    let mode = a === true ? true : false;
    this.darkMode.next(mode);
  }
}
