<a routerLink="../course/{{course._id}}" mat-list-item [ngClass]="{'dark': darkMode}">
    <img [src]="downloadUrl | async" alt="Course Video" class="course-thumbnail">
    <div class="course-details">
        <div class="course-title">{{ course.title }}</div>
        <div class="course-platform">{{ course.platform }}</div>
        <div class="course-description">{{ course.desc }}</div>
        <div class="course-rating" *ngIf="course.rating">
            <div class="course-rating-calc">{{ course.rating.score / course.rating.ratings | number:'1.0-1' }}</div>
            <div class="course-rating-stars" [ngClass]="{'dark': darkMode}">
                <div class="course-rating-star" [ngClass]="{'course-rating-star-black': (course.rating.score / course.rating.ratings) >= 1}"></div>
                <div class="course-rating-star" [ngClass]="{'course-rating-star-black': (course.rating.score / course.rating.ratings) >= 2}"></div>
                <div class="course-rating-star" [ngClass]="{'course-rating-star-black': (course.rating.score / course.rating.ratings) >= 3}"></div>
                <div class="course-rating-star" [ngClass]="{'course-rating-star-black': (course.rating.score / course.rating.ratings) >= 4}"></div>
                <div class="course-rating-star" [ngClass]="{'course-rating-star-black': (course.rating.score / course.rating.ratings) >= 5}"></div>
            </div>
            <div class="course-rating-ratings">({{ course.rating.ratings }})</div>
        </div>
    </div>
</a>
<mat-divider [ngClass]="{'dark': darkMode}"></mat-divider>