import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { Video } from '../../models/video';

@Component({
  selector: 'app-video-header',
  templateUrl: './video-header.component.html',
  styleUrls: ['./video-header.component.scss']
})
export class VideoHeaderComponent implements OnInit {
  @Input() currentVideo!: Video;
  @Input() darkMode: boolean = false;
  constructor(
    private userService: UserService
  ) { }
  get user () {
    return localStorage.getItem('user');
  }
  async addBookmark() {
    if (this.user) {
      let user: User = JSON.parse(this.user);
      await this.userService.postBookmark(user._id, this.currentVideo._id);
      alert(
        `Added ${this.currentVideo.title} to your bookmarks!`
      )
    }
  }
  ngOnInit(): void {
  }

}
