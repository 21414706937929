<mat-toolbar color="primary" class="kin-training-navbar" [ngClass]="{'dark': navDarkMode, 'light': !navDarkMode}">

  <div class="mat-toolbar-menu">
    <app-burger-menu lightDark="light"></app-burger-menu>
  </div>
  <img
    alt="Kinetek Logo"
    src="../../../assets/images/KLiM-logo-white.svg"
    class="kinetek-logo"
    routerLink="/home"
  />
  <a routerLink="/home" class="navbar-app-name">Learning <span class="contrast-blue">in</span> Motion</a>
  <div class="flex-spacer"></div>
  <div class="navbar-user" *ngIf="user">
    <div class="navbar-user-left">
      <i class="material-icons">notifications</i>
      <i class="material-icons" routerLink="/learning/course/favorites">favorite</i>
      <i class="material-icons" *ngIf="user.isAdmin" routerLink="/admin">admin_panel_settings</i>
      <i class="material-icons" (click)="logout()">logout</i>
    </div>
    <div class="navbar-user-right" routerLink="../learning/user">
      <img [src]="downloadUrl | async" alt="" class="navbar-user-right-img">
      <div class="navbar-user-right-name">{{ user.name }}</div>
    </div>
  </div>
  <div class="navbar-login" *ngIf="!user">
    <button mat-button matPrefix routerLink="/login">
      <i class="material-icons">login</i>
      <span>&nbsp; Login</span>
    </button>
  </div>
</mat-toolbar>