import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { Course } from '../../models/course';

@Component({
  selector: 'app-list-option-course',
  templateUrl: './list-option-course.component.html',
  styleUrls: ['./list-option-course.component.scss']
})
export class ListOptionCourseComponent implements OnInit {
  @Input() course!: Course;
  @Input() darkMode!: boolean;
  downloadUrl!: Observable<string>;
  constructor(
    private storageService: StorageService
  ) { }
  async getUrl() {
    this.downloadUrl = this.storageService.getUrlObs('course', this.course._id);    
  }
  ngOnInit(): void {
    this.getUrl();
  }

}
