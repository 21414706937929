<div class="course-titlebar">
    <nav class="course-titlebar-breadcrumbs">
        <a routerLink="/" class="course-titlebar-breadcrumbs-links">
            <i class="material-icons">home</i>
            <span class="link-span">home</span>
        </a>
        <!-- <mat-icon>arrow_forward_ios</mat-icon> -->
        <mat-icon>chevron_right</mat-icon>
        <a routerLink="../../courses" class="course-titlebar-breadcrumbs-links">
            <i class="material-icons">school</i>
            <span class="link-span">courses</span>
        </a>
    </nav>
    <div class="course-titlebar-spacer"></div>
    <h1 *ngIf="courseTitle" class="course-titlebar-title">{{ courseTitle }}</h1>
</div>
<button mat-flat-button color="accent">subscribe</button>
