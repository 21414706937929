import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject;
  darkMode: boolean = false;
  userForm = new FormGroup({
    name: new FormControl(''),
    company: new FormControl({value: '', disabled: true}),
    email: new FormControl(''),
    phone: new FormControl(''),
    active: new FormControl({value: true, disabled: true}),
    isAdmin: new FormControl({value: false, disabled: true}),
    settings: new FormGroup({
      darkMode: new FormControl('')
    }),
    imgUrl: new FormControl('')
  });
  user!: User;
  imgUrl!: string;
  fileToUpload!: File;
  uploadPercent!: Observable<number | undefined>;
  downloadUrl!: Observable<string>;
  user$!: Observable<User>;
  constructor(
    private sharedServices: SharedService,
    private userService: UserService,
    private authService: AuthService,
    private storageService: StorageService
  ) { }
  get color() {
    return this.darkMode ? 'accent' : 'primary';
  }
  getSettings() {
    this.sharedServices.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((a: boolean) => {
      this.darkMode = a;
    }, err => console.log(err), () => console.log('done'));
  }
  async putUser() {
    let putted = await this.userService.putUser(this.user._id, this.userForm.value);
  }
  darkModeToggle(a: User) {
    a.settings.darkMode = !a.settings.darkMode;
    this.userForm.patchValue({ settings: { darkMode: a.settings.darkMode }});
    this.sharedServices.toggleDarkMode(a.settings.darkMode);
    let parsedUser = JSON.stringify(a);
    localStorage.setItem('user', parsedUser);
  }
  async initUser() {
    const userId: any = await this.authService.verifyToken();
    const currentUser = await this.userService.getUserAcct(userId.status);
    this.user = currentUser;
    this.userForm.patchValue(currentUser);
    this.userForm.patchValue({
      company: currentUser.company.name
    });
    this.getUrl(currentUser.userId);
    this.user$ = this.userService.getUserAcctObs(userId.status);
  }
  async getUrl(userId: string) {
    this.downloadUrl = this.storageService.getUrlObs('user', userId);
    // const picList = await this.storageService.getURLs();
    // picList.map(async (a: any) => {
    //   a['imgurl'] = `${await a.getDownloadURL()}`;
    //   a['meta'] = `${await a.getMetadata()}`;
    //   a['adsf'] = `${a.name}`;
    //   console.log(a);
    //   return;
    // });
    
  }
  handleFileUpload(event: any) {
    if (event.target.files) {
      this.fileToUpload = event.target.files[0];
      const ret = this.storageService.uploadFile(event, 'user', this.user.userId);
      const fileName = event.target.files[0].name;
      const fileRef = ret.fileRef;
      const task = ret.task;
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(async () => {
          this.downloadUrl = fileRef.getDownloadURL();
          let url = await this.downloadUrl.toPromise();
        })
      ).subscribe();
    } else {
      return;
    }
  }
  ngOnInit(): void {
    this.initUser();
    this.getSettings();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}