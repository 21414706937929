import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent implements OnInit {
  @Input() lightDark: string = 'light';
  opened: boolean = false;
  links = [
    { name: 'Home', slug: '/home' },
    // { name: 'About', slug: '/about' },
    { name: 'Courses', slug: '/learning/courses' },
    { name: 'Instructor', slug: '/learning/instructor' },
    { name: 'User', slug: '/learning/user' },
    { name: 'Admin', slug: '/admin' },
    { name: 'Help', slug: '/help' }
  ];
  constructor() { }
  toggleOpened() {
    this.opened = !this.opened;
  }
  ngOnInit(): void {
  }

}
