import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noop, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SharedService } from 'src/app/services/shared.service';
import { Instructor } from 'src/app/models/instructor';
import { InstructorService } from '../../services/instructor.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { AdminCourseFormComponent } from 'src/app/components/admin-course-form/admin-course-form.component';
import { CourseService } from 'src/app/services/course.service';
import { Course } from 'src/app/models/course';
import { AdminVideoFormComponent } from 'src/app/components/admin-video-form/admin-video-form.component';
import { VideoService } from 'src/app/services/video.service';
import { Video } from 'src/app/models/video';
import { InstructorProfileComponent } from 'src/app/components/instructor-profile/instructor-profile.component';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-instructor',
  templateUrl: './instructor.component.html',
  styleUrls: ['./instructor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InstructorComponent implements OnInit, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  unsubscribe$ = new Subject;
  darkMode: boolean = false;
  instructor$!: Instructor;
  courses!: Course[];
  downloadUrl!: Observable<string>;
  instructorForm: FormGroup = new FormGroup({
    instructorId: new FormControl(''),
    firstName: new FormControl('', [
      Validators.required
    ]),
    lastName: new FormControl('', [
      Validators.required
    ]),
    title: new FormControl('', [
      Validators.required
    ]),
    email: new FormControl('', [
      Validators.required
    ]),
    socials: new FormGroup({
      facebook: new FormControl(''),
      linkedin: new FormControl(''),
      twitter: new FormControl(''),
      instagram: new FormControl(''),
      youtube: new FormControl('')
    }),
    bio: new FormControl('', [
      Validators.required
    ]),
    videos: new FormControl(''),
    courses: new FormControl(''),
    tags: new FormControl([]),
    updated: new FormControl('')
  });
  videoForm: FormGroup = new FormGroup({
    vimId: new FormControl(''),
    title: new FormControl(''),
    course: new FormControl(''),
    platform: new FormControl(''),
    version: new FormControl(''),
    instructor: new FormControl(''),
    keyTopics: new FormControl([]),
    desc: new FormControl(''),
    tags: new FormControl([]),
    updated: new FormControl(new Date())
  });
  constructor(
    private sharedService: SharedService,
    private instructorService: InstructorService,
    private courseService: CourseService,
    private videoService: VideoService,
    private dialog: MatDialog,
    private storageService: StorageService
  ) { }

  checkStorage() {
    let user = localStorage.getItem('user');
    if (user) {
      let parsedUser = JSON.parse(user);
      console.log(parsedUser);
      this.instructorService.getInstructorAcct(parsedUser.userId).pipe(takeUntil(this.unsubscribe$))
        .subscribe((instructor: Instructor) => {
          this.instructor$ = instructor;
          this.instructorForm.patchValue(instructor);
        }, err => console.log(err), () => console.log('got instructor'));
      this.getUrl(parsedUser.userId);
    }
  }

  async getUrl(userId: string) {
    this.downloadUrl = this.storageService.getUrlObs('user', userId);
  }

  createCourse() {
    const instructors = [];
    instructors.push(this.instructor$);
    let dialog = this.dialog.open(AdminCourseFormComponent, {
      backdropClass: 'course-form-dialog-background',
      panelClass: 'course-form-dialog-container',
      data: {
        course: this.courseService.getCourseForm(),
        instructors,
        platforms: this.sharedService.platforms
      }
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((returnedData) => {
        returnedData ? this.addCourse(returnedData.course, returnedData.image) : noop();
      });
  }

  createVideo() {
    const instructors = [];
    instructors.push(this.instructor$);
    let dialog = this.dialog.open(AdminVideoFormComponent, {
      backdropClass: 'course-form-dialog-background',
      panelClass: 'course-form-dialog-container',
      data: {
        videoForm: this.videoForm,
        courses: this.courses,
        instructors,
        platforms: this.sharedService.platforms
      }
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.addVideo(data.video.value, data.image);
        }
      }, err => console.log(err), () => console.log('done'));
  }

  openProfile() {
    let dialog = this.dialog.open(InstructorProfileComponent, {
      backdropClass: 'course-dialog-background',
      panelClass: 'course-dialog-container',
      data: this.instructor$
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe(a => console.log(a), err => console.log(err), () => console.log('done'));
  }

  getSettings() {
    this.sharedService.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: boolean) => {
      this.darkMode = data;
    }, err => console.log(err), () => console.log('done'));
  }

  getCourses() {
    this.courseService.getCourses().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: Course[]) => {
      this.courses = data;
    }, err => console.log(err), () => console.log('done'));
  }

  async putInstructor(id: string, instructor: Instructor) {
    let putted = await this.instructorService.putInstructor(id, instructor);
    console.log(putted);
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.instructor$.tags.push(value);

      this.instructorForm.patchValue({
        tags: this.instructor$.tags
      });

      if (input) {
        input.value = '';
      }
    }
  }

  removeTag(tag: string) {
    const index = this.instructor$.tags.indexOf(tag);

    if (index >= 0) {
      this.instructor$.tags.splice(index, 1);

      this.instructorForm.patchValue({
        tags: this.instructor$.tags
      });
    }
  }
  
  async addCourse(course: FormGroup, file: File) {
    const Course = course.value
    Course.rating = {
      score: 0,
      ratings: 0
    }
    if (course.valid) {
      let posted = await this.courseService.addCourse(Course);
      posted._id ? this.postImage(posted._id, 'course', file) : noop();
      posted._id ? this.instructorService.addCourse(posted.instructor as unknown as string, posted._id) : noop();
      // this.getInstructor(posted.instructor._id);
    } else {
      alert('Something went wrong');
    }
  }

  async addVideo(video: Video, image: File) {
    let posted = await this.videoService.addVideo(video);
    if (posted._id) {
      this.postImage(posted._id, 'video', image);
      let updated = await this.courseService.addVideo(posted.course as unknown as string, posted._id);
    }
  }

  async postImage(id: string, type: string, file: File) {
  }

  resetForm() {
    this.instructorForm.reset();
    this.instructorForm.markAsPristine();
    this.instructor$.tags = [];
  }

  ngOnInit(): void {
    this.getSettings();
    this.checkStorage();
    this.getCourses();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}