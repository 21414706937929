import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-titlebar',
  templateUrl: './course-titlebar.component.html',
  styleUrls: ['./course-titlebar.component.scss']
})
export class CourseTitlebarComponent implements OnInit {
  @Input() courseTitle!: string;
  constructor() { }

  ngOnInit(): void {
  }

}
