<div class="video-details">{{ currentVideo.title }} - {{ currentVideo.platform }} {{ currentVideo.version }}</div>
<div class="video-date" [ngClass]="{'dark': darkMode}">
    <div class="video-date-left">
        <div class="video-date-left-date">{{ currentVideo.updated | date }}</div>
        <div class="video-date-left-tags">
            <a href="" *ngFor="let tag of currentVideo.tags">#{{ tag }} &nbsp;</a>
        </div>
    </div>
    
    <div class="video-date-spacer"></div>
    <div class="video-date-right">
        <mat-icon class="video-date-right-bookmark" (click)="addBookmark()">bookmark</mat-icon>
        <!-- <a href="" target="_blank" title="Bookmark"><mat-icon>bookmark</mat-icon></a> -->
        <a href="http://costpoint.kcl-dev.com:7009/cploginform.htm?1549968181" target="_blank" title="Costpoint"><mat-icon>apps</mat-icon></a>
        <a href="http://cognos11.kcl-dev.com/ibmcognos/bi/" target="_blank" title="Cognos"><mat-icon>insert_chart</mat-icon></a>
        <a href="" target="_blank" title="Attachments"><mat-icon>attach_file</mat-icon></a>
        <a href="" target="_blank" title="Downloads"><mat-icon>folder</mat-icon></a>
    </div>
</div>