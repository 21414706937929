import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { CourseService } from 'src/app/services/course.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { InstructorProfileComponent } from '../../components/instructor-profile/instructor-profile.component';
import { Course } from '../../models/course';
import { Video } from '../../models/video';
import { CoursesService } from '../../services/courses.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CourseComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  darkMode!: boolean;
  course$!: Observable<Course>;
  user$!: Observable<User>;
  currentVideo!: Video;
  profiles!: any;
  constructor(
    private courseService: CourseService,
    private coursesService: CoursesService,
    public activatedRoute: ActivatedRoute,
    private sharedServices: SharedService,
    public dialog: MatDialog,
    public userService: UserService
  ) { }
  getSettings() {
    this.sharedServices.darkMode
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((e: boolean) => {
        this.darkMode = e;
      }, err => console.log(err), () => console.log('Fetch Settings'));
  }
  getCourseId(a: ActivatedRoute) {
    if (a.snapshot.params.courseId) {
      return a.snapshot.params.courseId;
    } else {
      return null;
    }
  }
  getVideos() {
    if (this.getCourseId(this.activatedRoute) === 'favorites') {
      return this.getUser();
    } else { return this.getCourse() }
  }
  async getCourse() {
    const courseId = this.getCourseId(this.activatedRoute);
    this.course$ = this.coursesService.getCourse(courseId);
  }
  async getUser() {
    let locStore = localStorage.getItem('user');
    let userParsed = locStore ? JSON.parse(locStore) : null;
    if (userParsed) {
      this.user$ = this.userService.getUserAcctObs(userParsed.userId);
    }
  }
  async rateCourse(id: string, score: number) {
    let putted = await this.courseService.rateCourse(id, score);
    console.log(putted);
  }
  openProfile(profile: Event) {
    let dialog = this.dialog.open(InstructorProfileComponent, {
      backdropClass: 'course-dialog-background',
      panelClass: 'course-dialog-container',
      data: profile
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe(a => console.log(a), err => console.log(err), () => console.log('done'));
  }
  setVideo(vid: Video) {
    this.currentVideo = vid;
  }
  addBookmark(event: string) {
    alert(`Adding bookmark: ${event}`);
  }
  switchDarkMode() {
    this.darkMode = !this.darkMode;
  }
  ngOnInit(): void {
    this.getVideos();
    this.getSettings();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
