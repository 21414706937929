import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HTMLInputEvent } from 'src/app/models/htmlinput-event';
import { Instructor } from 'src/app/models/instructor';

@Component({
  selector: 'app-admin-course-form',
  templateUrl: './admin-course-form.component.html',
  styleUrls: ['./admin-course-form.component.scss']
})
export class AdminCourseFormComponent implements OnInit, OnDestroy {
  $unsubscribe = new Subject;
  course = new FormGroup({});
  instructors!: Instructor[];
  platforms!: string[];
  fileToUpload!: File;
  file!: Event;
  constructor(
    public dialogRef: MatDialogRef<AdminCourseFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  handleFileInput(event: any) {
    if (event.target.files) {
      console.log(event.target.files[0]);
      this.fileToUpload = event.target.files[0];
      this.file = event;
    } else {
      return;
    }
  }
  submitCourse() {
    this.dialogRef.close({
      course: this.course,
      image: this.fileToUpload ? this.fileToUpload : null,
      instructor: this.course.controls.instructor.dirty ? this.course.value.instructor : null,
      file: this.file
    });
  }
  watchInstructor() {
    this.course.controls.instructor.valueChanges
      .pipe(
        takeUntil(this.$unsubscribe)
      ).subscribe((data) => {
        console.log(this.course);
        console.log(data);
      }, err => console.log(err), () => console.log('done'));
  }
  onNoClick(): void {
    this.course.reset();
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.course = this.data.course;
    this.instructors = this.data.instructors;
    this.platforms = this.data.platforms;
    console.log(this.data);
  }
  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
