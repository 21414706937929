<div *ngIf="companies$ | async as companies" class="admin-company">
    <mat-card *ngFor="let company of companies" class="admin-company-card" [ngClass]="{'dark': darkMode}">
        <mat-card-header>
            <mat-card-title class="admin-company-card-title">{{ company.name }}</mat-card-title>
            <mat-card-subtitle class="admin-company-card-subtitle">{{ company.poc }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content></mat-card-content>
        <mat-card-actions>
            <button mat-button (click)="crudCompany('put', company)">edit</button>
            <button mat-button color="warn" (click)="deleteCompany(company)">delete</button>
        </mat-card-actions>
    </mat-card>
    <div class="admin-action-row">
        <button mat-fab color="accent" class="admin-add-button" (click)="crudCompany('post')">
            <i class="fas fa-plus admin-company-add"></i>
        </button>
    </div>
</div>