<div class="user-table">
  <mat-form-field class="user-filter" color="primary">
    <mat-label class="user-filter-label">Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input class="user-filter-input">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z10 user-table-table">
    
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element" class="mat-cell-cap"> {{element.name}} </td>
    </ng-container>
  
    <!-- Company Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef> Company </th>
      <td mat-cell *matCellDef="let element" class="mat-cell-cap"> {{element.company.name}} </td>
    </ng-container>
  
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> E-mail </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
  
    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> Phone </th>
      <td mat-cell *matCellDef="let element"> {{element.phone | phone}} </td>
    </ng-container>

    <!-- Active Column -->
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef> Active </th>
      <td mat-cell *matCellDef="let element" class="mat-cell-cap"> {{element.active}} </td>
    </ng-container>

    <!-- Admin Column -->
    <ng-container matColumnDef="admin">
      <th mat-header-cell *matHeaderCellDef> Admin </th>
      <td mat-cell *matCellDef="let element" class="mat-cell-cap"> {{element.isAdmin}} </td>
    </ng-container>

    <!-- Reset Password Column -->
    <!-- <ng-container matColumnDef="resetpw">
      <th mat-header-cell *matHeaderCellDef> Reset Password </th>
      <td mat-cell class="mat-cell-cap" *matCellDef="let element">
        reset password
      </td>
    </ng-container> -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>

  </table>
</div>

  

  