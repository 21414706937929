import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-user-form',
  templateUrl: './admin-user-form.component.html',
  styleUrls: ['./admin-user-form.component.scss']
})
export class AdminUserFormComponent implements OnInit {
  user = new FormGroup({});
  constructor(
    public dialogRef: MatDialogRef<AdminUserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  submitUser() {
    this.dialogRef.close(this.user);
  }
  onNoClick(): void {
    this.user.reset();
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.user = this.data.user;
  }
}
