import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'p0-menu-screen',
  templateUrl: './menu-screen.component.html',
  styleUrls: ['./menu-screen.component.scss']
})
export class MenuScreenComponent implements OnInit {
  @Input() showMenu!: boolean;
  @Input() links: any;
  @Output() showMenuOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }
  swapFlag() {
    this.showMenuOut.emit(!this.showMenu);
  }
  ngOnInit(): void {
  }

}
