import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { noop, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AdminUserFormComponent } from 'src/app/components/admin-user-form/admin-user-form.component';
import { User } from 'src/app/models/user';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminUserComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject;
  darkMode: boolean = false;
  users$!: Observable<User[]>;
  userForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    company: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    active: new FormControl(true),
    isAdmin: new FormControl(false),
    settings: new FormGroup({
      darkMode: new FormControl(false)
    }),
    bookmarks: new FormControl('')
  });
  constructor(
    private sharedService: SharedService,
    private userService: UserService,
    public dialog: MatDialog
  ) { }
  getSettings() {
    this.sharedService.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: boolean) => {
      this.darkMode = data;
    }, err => console.log(err), () => console.log('done'));
  }
  getUsers(company: string) {
    this.users$ = this.userService.getUsers(company);

    this.userService.getUsers(company).pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        console.table(data);
      }, err => console.log(err), () => console.log('done'));
  }
  crudUser(action: string, user?: User) {
    user ? this.userForm.patchValue(user) : noop();
    let dialog = this.dialog.open(AdminUserFormComponent, {
      backdropClass: 'user-form-dialog-background',
      panelClass: 'user-form-dialog-container',
      data: {
        user: this.userForm
      }
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (action === 'post' && data) {
          this.userForm.patchValue(data);
          this.userForm.patchValue({
            settings: {
              darkMode: false
            },
            bookmarks: []
          });
          this.postUser(this.userForm.value);
        } else if (action === 'put' && user && data) {
          console.log(data);
        } else {
          this.userForm.reset();
        }
      }, err => console.log(err), () => console.log('done'));
  }
  postUser(user: User) {
    console.log(user);
    let posted = this.userService.postUser(user);
    this.getUsers('6086ea3e7e82ba5fe42bdb5a');
    this.resetForm();
  }
  putUser(id: string, user: User) {
    let putted = this.userService.putUser(id, user);
    this.getUsers('6086ea3e7e82ba5fe42bdb5a');
    console.log(putted);
  }
  deleteUser(id: string) {
    let deleted = this.userService.deleteUser(id);
    this.getUsers('6086ea3e7e82ba5fe42bdb5a');
    console.log(deleted);
  }
  resetForm() {
    this.userForm.reset();
    this.userForm.markAsPristine();
  }
  ngOnInit(): void {
    this.getSettings();
    this.getUsers('6086ea3e7e82ba5fe42bdb5a');
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
