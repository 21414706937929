<div class="courses" [ngClass]="{'dark': darkMode}">
    <mat-drawer-container class="courses-drawer-container">
        <mat-drawer mode="side" opened class="container-drawer" [ngClass]="{'dark': darkMode}">
            <app-sm-accordion
                title="platform"
                [values]="platforms"
                [darkMode]="darkMode"
                (filterVals)="updateFilters($event)"
            ></app-sm-accordion>
            <!-- <app-sm-accordion title="instructor" [values]="instructors" [darkMode]="darkMode" (filterVals)="updateFilters($event)"></app-sm-accordion> -->
        </mat-drawer>
        <mat-drawer-content class="container-content">
            <mat-list *ngIf="filteredCourses" class="courses-list">
                <app-list-option-course [course]="course" [darkMode]="darkMode" *ngFor="let course of filteredCourses"></app-list-option-course>
            </mat-list>
        </mat-drawer-content>
    </mat-drawer-container>
</div>