<div class="sm-accordion" [ngClass]="{'dark': darkMode}">
    <div class="sm-accordion-title" [ngClass] = "{'sm-accordion-title-open': panelStatus}" *ngIf="title" (click)="swapStatus()">
        <div class="sm-accordion-title-text">
            <span class="text-positioning">{{ title }}</span>
        </div>
        <div class="sm-accordion-title-icon">
            <mat-icon>chevron_right</mat-icon>
        </div>
    </div>
    <div class="sm-accordion-content" [ngClass]="{'sm-accordion-content-open': panelStatus}">
        <mat-list *ngIf="values">
            <mat-list-item *ngFor="let value of values">
                <mat-checkbox color="warn" (change)="updateFilterVals($event)" [value]="value" [name]="title" [ngClass]="{'dark': darkMode}">{{ value.value }}</mat-checkbox>
            </mat-list-item>
        </mat-list>
    </div>
</div>
