<div class="video-description" [ngClass]="{'dark': darkMode}">
    <div class="flex-left">
      <div class="flex-left-container">
        <img class="instructor-img" [src]="downloadUrl | async" alt="Profile Image" *ngIf="currentVideo.instructor">
        <div class="instructor-deets">
          <div class="instructor-name" (click)="sendInstructor()">{{ currentVideo.instructor.firstName }} {{ currentVideo.instructor.lastName }}</div>
          <div class="instructor-title" (click)="sendInstructor()">Instructor Profile</div>
          <div>
            <a href="" target="_blank" title="LinkedIn Profile" class="instructor-socials">
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a href="mailto:{{ currentVideo.instructor.email }}" [title]="currentVideo.instructor.email" class="instructor-socials">
              <i class="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="video-description-details">
        <div class="video-description-title">Description</div>
        <div class="video-description-long">{{ currentVideo.desc }}</div>
      </div>
    </div>
    <div class="flex-spacer"></div>
    <div class="flex-right">
      <div class="key-learning-title">Key Learning Topics</div>
      <mat-list role="list" class="key-learning-list">
        <mat-list-item role="listitem" *ngFor="let tag of currentVideo.keyTopics" [ngClass]="{'dark': darkMode}"><mat-icon>check</mat-icon>&nbsp; {{ tag }}</mat-list-item>
      </mat-list>  
    </div>
</div>