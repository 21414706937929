import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Instructor } from '../models/instructor';

@Injectable({
  providedIn: 'root'
})
export class InstructorService {
  uri: string = 'https://learninginmotion.herokuapp.com/api/instructor';
  extUri: string = 'https://learninginmotion.herokuapp.com/api/cross/instructor';
  extRem: string = 'https://learninginmotion.herokuapp.com/api/remove/course/instructor';
  instAcct: string = 'https://learninginmotion.herokuapp.com/api/instructor-account';
  constructor(
    private http: HttpClient
  ) { }
  getInstructor(id: string) {
    return this.http.get<Instructor>(`${this.uri}/${id}`);
  }
  getInstructors() {
    return this.http.get<Instructor[]>(`${this.uri}s`);
  }
  addInstructor(instructor: Instructor) {
    return this.http.post<Instructor>(this.uri, instructor).toPromise();
  }
  putInstructor(id: string, instructor: Instructor) {
    return this.http.put<Instructor>(`${this.uri}/${id}`, instructor).toPromise();
  }
  deleteInstructor(id: string) {
    return this.http.delete(`${this.uri}/${id}`).toPromise();
  }
  addCourse(id: string, courseId: string) {
    return this.http.put<Instructor>(`${this.extUri}/${id}/${courseId}`, {}).toPromise();
  }
  removeCourse(id: string, courseId: string) {
    return this.http.put<Instructor>(`${this.extRem}/${id}/${courseId}`, {}).toPromise();
  }
  getInstructorAcct(id: string) {
    return this.http.get<Instructor>(`${this.instAcct}/${id}`);
  }
}
