import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Question } from '../models/question';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  uri: string = 'https://learninginmotion.herokuapp.com/api/help';
  constructor(
    private http: HttpClient
  ) { }
  getQuestions() {
    return this.http.get<Question[]>(`${this.uri}`);
    // let questions = new Observable(subscriber => {
    //   subscriber.next(QUESTIONS);
    // });

    // return questions;
  }

  addQuestion(question: Question) {
    return this.http.post<Question>(`${this.uri}`, question).toPromise();
  }

  updateQuestion(id: string, question: Question) {
    return this.http.put<Question>(`${this.uri}/${id}`, question).toPromise();
  }

  deleteQuestion(id: string) {
    return this.http.delete<Question>(`${this.uri}/${id}`).toPromise();
  }
}


const QUESTIONS = [
  { category: 'logging in', question: 'how do i log in?', answer: 'click on the log in link in the top-right corner' },
  { category: 'registering', question: 'how do i register?', answer: 'from the log in page, click the link under the log in section' },
  { category: 'favorites', question: 'where can i see my favorites?', answer: 'click the heart icon in the top navigation'}
]