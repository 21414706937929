<form [formGroup]="user" *ngIf="user" class="user-form">
    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Company</mat-label>
        <input matInput type="text" formControlName="company">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>E-mail</mat-label>
        <input matInput type="email" formControlName="email">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput type="phone" formControlName="phone">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Active</mat-label>
        <input matInput type="text" formControlName="active">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Admin</mat-label>
        <input matInput type="text" formControlName="isAdmin">
    </mat-form-field>
</form>

<button mat-button color="primary" (click)="submitUser()">submit</button>
<button mat-button color="accent" (click)="onNoClick()">cancel</button>