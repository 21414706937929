<div *ngIf="users$ | async as users" class="admin-user">

    <!-- <mat-card *ngFor="let user of users" class="admin-user-card" [ngClass]="{'dark': darkMode}">
        <mat-card-header>
            <mat-card-title class="admin-user-card-title">{{ user.name }}</mat-card-title>
            <mat-card-subtitle class="admin-user-card-subtitle">{{ user.email }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content></mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions class="admin-user-card-actions">
            <button mat-button (click)="crudUser('put', user)">edit</button>
            <button mat-button color="warn" (click)="deleteUser(user._id)">delete</button>
        </mat-card-actions>
    </mat-card> -->

    <wf-mat-table *ngIf="users$ | async as users" [users]="users"></wf-mat-table>

    <div class="admin-action-row">
        <button mat-fab color="accent" class="admin-add-button" (click)="crudUser('post')">
            <i class="fas fa-plus admin-user-add"></i>
        </button>
    </div>
</div>
