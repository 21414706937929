<div class="instructor" [ngClass]="{'dark': darkMode}" *ngIf="instructor$">
    <mat-card class="instructor-card">
        <form [formGroup]="instructorForm">
            <mat-card-title-group>
                <img style="border-radius: 50%;" mat-card-lg-image [src]="downloadUrl | async">
                <mat-card-title class="capitalize">
                    <mat-form-field appearance="standard">
                        <mat-label>First Name</mat-label>
                        <input class="capitalize" type="text" matInput placeholder="First Name" formControlName="firstName">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>Last Name</mat-label>
                        <input class="capitalize" type="text" matInput placeholder="Last Name" formControlName="lastName">
                    </mat-form-field>
                </mat-card-title>
                <mat-card-subtitle>
                    <mat-form-field appearance="standard">
                        <mat-label>Title</mat-label>
                        <input class="capitalize" type="text" matInput placeholder="Title" formControlName="title">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>E-mail</mat-label>
                        <input type="email" matInput placeholder="E-mail" formControlName="email">
                    </mat-form-field>
                </mat-card-subtitle>
            </mat-card-title-group>
            <br>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Bio</mat-label>
                    <textarea type="textarea" matInput placeholder="Bio" formControlName="bio"></textarea>
                </mat-form-field>
                <!-- <mat-nav-list>
                    <a mat-list-item href="..." *ngFor="let link of links"> {{ link }} </a>
                </mat-nav-list> -->
                <!-- <mat-list role="list" *ngFor="let course of instructor$.courses" class="capitalize">
                    <mat-list-item role="listitem">
                        {{ course.platform }} {{ course.title }}
                    </mat-list-item>
                </mat-list> -->
                <mat-form-field style="width: 100%;">
                    <mat-label>Courses</mat-label>
                    <mat-chip-list aria-label="Course selection">
                        <mat-chip *ngFor="let course of instructor$.courses">{{ course.platform + ' ' + course.title }}</mat-chip>
                    </mat-chip-list>  
                </mat-form-field>  
                <mat-form-field class="example-chip-list" style="width: 100%;">
                    <mat-label>Tags</mat-label>
                    <mat-chip-list #chipList aria-label="Tag selection">
                      <mat-chip *ngFor="let tag of instructor$.tags" [selectable]="selectable"
                               [removable]="removable" (removed)="removeTag(tag)">
                        {{ tag }}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>
                      <input placeholder="New tag..."
                             [matChipInputFor]="chipList"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             [matChipInputAddOnBlur]="addOnBlur"
                             (matChipInputTokenEnd)="addTag($event)">
                    </mat-chip-list>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions class="instructor-card-actions">
                <div class="instructor-card-actions-buttons">
                    <button mat-button [disabled]="!instructorForm.valid" (click)="putInstructor(instructor$._id, instructorForm.value)">submit</button>
                    <button mat-button color="primary" (click)="createCourse()">create course</button>
                    <button mat-button color="primary" (click)="createVideo()">create video</button>
                    <a mat-button (click)="openProfile()">view profile</a>
                </div>
            </mat-card-actions>
        </form>
    </mat-card>
</div>