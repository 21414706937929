import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { Course } from '../../models/course';
import { Filter } from '../../models/filter';
import { Filters } from '../../models/filters';
import { CoursesService } from '../../services/courses.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>();
  courses$!: Observable<Course[]>;
  private courses!: Course[];
  filteredCourses!: Course[];
  darkMode!: boolean;
  panelOpenState: boolean = false;
  platforms!: any[];
  filters: Filters = {
    platform: []
  }
  constructor(
    private coursesService: CoursesService,
    private sharedServices: SharedService
  ) { }
  getSettings() {
    this.sharedServices.darkMode
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((e: boolean) => {
        this.darkMode = e;
      }, err => console.log(err), () => console.log('done getting settings'));

    this.platforms = PLATFORM;
  }
  getCourses() {
    this.coursesService.getCourses()
      .pipe(take(1))
      .subscribe((e: Course[]) => {
        this.courses = e;
        this.filteredCourses = this.courses;
      }, err => console.log(err), () => console.log('done loading courses'));
  }
  updateFilters(e: any) {
    if (e.checked) {
      this.addFilter(e.value);
    } else {
      this.removeFilter(e.value);
    }
  }
  addFilter(val: Filter) {
    let retArray: Course[] = [];
    this.filters[val.key].push(val);
    this.filters[val.key].forEach((a: Filter) => {
     (this.filterCourses(a)).forEach((b: Course) => {
      retArray.push(b);
     });
    });
    console.log(retArray);
    this.filteredCourses = retArray;
  }
  removeFilter(val: any) {
    let retArray: Course[] = [];
    let i = this.filters[val.key].findIndex((a: Filter) => {
      return a.id === val.id;
    });
    this.filters[val.key].splice(i, 1);
    this.filters[val.key].forEach((a: Filter) => {
      (this.filterCourses(a)).forEach((b: Course) => {
       retArray.push(b);
      });
    });
    if (this.filters[val.key].length === 0) {
      this.filteredCourses = this.courses;
    } else {
      this.filteredCourses = retArray;
    }
  }
  filterCourses(filter: Filter) {
    return this.courses.filter((a: any) => {
      return a[filter.key] === filter.value;
    });
  }
  ngOnInit(): void {
    this.getSettings();
    this.getCourses();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

const PLATFORM = [
  { id: 53452, key: 'platform', value: 'cognos analytics' },
  { id: 76545, key: 'platform', value: 'costpoint' },
  { id: 98732, key: 'platform', value: 'kinetek performance planning' }
];

const INSTRUCTOR = [
  { id: 13423, key: 'instructor', value: 'aaron williams'},
  { id: 77543, key: 'instructor', value: 'shimi minhas'},
  { id: 34534, key: 'instructor', value: 'chad bishop'},
  { id: 88864, key: 'instructor', value: 'phong starzewski'},
  { id: 89942, key: 'instructor', value: 'ryan turpyn'}
];