import { Injectable } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private uri: string = 'https://firebasestorage.googleapis.com/v0/b/kin-learning.appspot.com/o/'
  constructor(private readonly storage: AngularFireStorage) { }
  getAll(folder: string) {
    return this.storage.ref(folder).listAll().toPromise();
  }
  async getURLs() {
    const ref = this.storage.ref('user');
    const picList = (await ref.listAll().toPromise()).items;
    console.log(picList);
    return picList;
  }

  getUrl(bucket: string, id: string) {
    const ref = this.storage.ref(`${bucket}/${id}`);
    // const abc = (await ref.getDownloadURL().toPromise());
    return ref.getDownloadURL().toPromise();
  }

  getUrlObs(bucket: string, id: string) {
    const ref = this.storage.ref(`${bucket}/${id}`);
    return ref.getDownloadURL();
  }

  uploadFile(event: any, folder: string, name: string) {
    const file = event.target.files[0];
    const fileName = folder === 'list' ? event.target.files[0].name : name;
    const filePath = `${folder}/${fileName}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    return { fileRef, task }
  }

  deleteFile(id: string, folder: string, name: string) {
    const filePath = `${id}/${folder}/${name}`;
    const fileRef = this.storage.ref(filePath);
    return fileRef.delete().toPromise();
  }
}