<form [formGroup]="filterForm" class="admin-video-filter-form">
    <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput formControlName="tag">
    </mat-form-field>
</form>
<div *ngIf="videos" class="admin-video">
    <mat-card *ngFor="let video of videos" class="admin-video-card" [ngClass]="{'dark': darkMode}">
        <app-video-image [video]="video"></app-video-image>
        <mat-card-header>
            <mat-card-title class="admin-video-card-title">{{ video.title }}</mat-card-title>
            <mat-card-subtitle class="admin-video-card-subtitle">{{ video.course.title }}</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-actions class="admin-video-card-actions">
            <button mat-button (click)="crudVideo('put', video)">edit</button>
            <button mat-button color="warn" (click)="deleteVideo(video._id)">delete</button>
        </mat-card-actions>
    </mat-card>
    <div class="admin-action-row">
        <button mat-fab color="accent" class="admin-add-button" (click)="crudVideo('post')">
            <i class="fas fa-plus admin-video-add"></i>
        </button>
    </div>
</div>