<div class="pricing">
    <div class="pricing-card">
        <div class="pricing-card-side pricing-card-side-front">
            <h4 class="pricing-card-heading">
                <span class="pricing-card-heading-span pricing-card-heading-span--1">
                    free
                </span>
            </h4>
            <div class="pricing-card-picture pricing-card-picture--1"></div>
            <mat-list class="pricing-card-list">
                <mat-list-item role="listitem" class="pricing-card-list-item">Basic course catalog</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">No commitment</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Access to live webinars</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Way more benefits</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Last of the benefits</mat-list-item>
            </mat-list>
        </div>
        <div class="pricing-card-side pricing-card-side-back pricing-card-side-back--1">
            <div class="pricing-cost">free</div>
        </div>
    </div>

    <div class="pricing-card">
        <div class="pricing-card-side pricing-card-side-front">
            <h4 class="pricing-card-heading">
                <span class="pricing-card-heading-span pricing-card-heading-span--2">
                    flexible
                </span>
            </h4>
            <div class="pricing-card-picture pricing-card-picture--2"></div>
            <mat-list class="pricing-card-list">
                <mat-list-item role="listitem" class="pricing-card-list-item">Full course catalog</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">No commitment</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Cancel anytime</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Way more benefits</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Last of the benefits</mat-list-item>
            </mat-list>
        </div>
        <div class="pricing-card-side pricing-card-side-back pricing-card-side-back--2">
            <div class="pricing-cost">1,000</div>
            <div class="pricing-term">per month</div>
        </div>
    </div>

    <div class="pricing-card">
        <div class="pricing-card-side pricing-card-side-front">
            <h4 class="pricing-card-heading">
                <span class="pricing-card-heading-span pricing-card-heading-span--3">
                    premium
                </span>
            </h4>
            <div class="pricing-card-picture pricing-card-picture--3"></div>
            <mat-list class="pricing-card-list">
                <mat-list-item role="listitem" class="pricing-card-list-item">Full course catalog</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Term discount</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Live content</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Access user history</mat-list-item>
                <mat-list-item role="listitem" class="pricing-card-list-item">Learning plans</mat-list-item>
            </mat-list>
        </div>
        <div class="pricing-card-side pricing-card-side-back pricing-card-side-back--3">
            <div class="pricing-cost">10,000</div>
            <div class="pricing-term">per year</div>
        </div>
    </div>
</div>
