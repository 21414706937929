<div class="nasba">

    <div class="flex-container">

        <div class="nasba-logos">
            <img src="../../../assets/images/app-images/Registry2color.png" alt="NASBA Logo" class="nasba-logos-nasba">
        </div>
    
        <div class="nasba-statement">
            Kinetek is registered with the National Association of State Boards
            of Accountancy (NASBA) as a sponsor of continuing professional education on the National
            Registry of CPE Sponsors. State boards of accountancy have final authority on the acceptance of
            individual courses for CPE credit. Complaints regarding registered sponsors may be submitted to
            the National Registry of CPE Sponsors through its website: 
            
            <a href="http://www.NASBARegistry.org" target="_blank" class="nasba-statement-link">www.NASBARegistry.org</a>
        </div>

    </div>

</div>