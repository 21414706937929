import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Course } from 'src/app/models/course';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-course-image',
  templateUrl: './course-image.component.html',
  styleUrls: ['./course-image.component.scss']
})
export class CourseImageComponent implements OnInit {
  @Input() course!: Course;
  downloadUrl!: Observable<string>;
  constructor(
    private storageService: StorageService
  ) { }
  async getUrl() {
    this.downloadUrl = this.storageService.getUrlObs('course', this.course._id);    
  }
  ngOnInit(): void {
    this.getUrl();
  }

}
