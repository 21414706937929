import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-subnav',
  templateUrl: './admin-subnav.component.html',
  styleUrls: ['./admin-subnav.component.scss']
})
export class AdminSubnavComponent implements OnInit {
  @Input() listItems!: any[];
  @Input() darkMode: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
