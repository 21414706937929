<div class="kin-user" [ngClass]="{'dark': darkMode}">
  <div class="kin-user-body">
    <mat-card class="kin-user-body-profile" [ngClass]="{'dark': darkMode}" *ngIf="user">
      <mat-card-header>
        <!-- <div mat-card-avatar class="kin-user-body-profile-image" style="background-image: url({{imgUrl}});"></div> -->
        <!-- <img [src]="imgUrl" alt="" mat-card-avatar class="kin-user-body-profile-image"> -->
        <img [src]="downloadUrl | async" alt="" mat-card-avatar class="kin-user-body-profile-image">
        <mat-card-title class="field-cap">{{ user.name }}</mat-card-title>
        <mat-card-subtitle class="field-cap">{{ user.company.name }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="userForm" class="user-form">
          <mat-form-field class="user-form-field" [color]="color">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" class="field-cap">
          </mat-form-field>
          <mat-form-field class="user-form-field" [color]="color">
              <mat-label>Company Name</mat-label>
              <input matInput formControlName="company" class="field-cap">
          </mat-form-field>
          <mat-form-field class="user-form-field" [color]="color">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">
          </mat-form-field>
          <mat-form-field class="user-form-field" [color]="color">
              <mat-label>Phone</mat-label>
              <input matInput formControlName="phone" type="tel">
          </mat-form-field>
          <!-- <mat-slide-toggle
            class="user-form-toggles"
            color="primary"
            disabled="true"
            [checked]="user.isAdmin"
          >company admin</mat-slide-toggle> -->
          <mat-slide-toggle
            class="user-form-toggles"
            color="primary"
            disabled="false"
            [checked]="user.settings.darkMode"
            (change)="darkModeToggle(user)"
          >dark mode</mat-slide-toggle>
        </form>
      </mat-card-content>
      <mat-progress-bar *ngIf="uploadPercent | async as perc" mode="determinate" [value]="perc"></mat-progress-bar>
      <mat-card-actions class="kin-user-actions">
        <button mat-button color="success" (click)="putUser()">SAVE</button>
        <button mat-button color="warn">CANCEL</button>
        <!-- <input type="file" id="userFileInput" (change)="handleFileUpload($event)"> -->
        <label for="userFileInput" class="images-profile-label">
          <input id="userFileInput" type="file" class="images-profile-button" (change)="handleFileUpload($event)"/>
          <i class="material-icons">add_a_photo</i>
        </label>
        <p class="images-profile-action"> add/change picture </p>
      </mat-card-actions>
    </mat-card>          
  </div>
</div>