import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user$!: Observable<User>;
  private uri = 'https://learninginmotion.herokuapp.com/api';
  constructor(
    private http: HttpClient
  ) { }
  getUser(id: string) {
    return this.http.get<User>(`${this.uri}/user/${id}`).toPromise();
    // let user = await this.http.get<User>(`${this.uri}/${id}`).toPromise();
  }
  getUsers(company: string) {
    return this.http.get<User[]>(`${this.uri}/users/${company}`);
  }
  postUser(user: User) {
    return this.http.post<User>(`${this.uri}/user`, user).toPromise();
  }
  putUser(id: string, user: User) {
    return this.http.put<User>(`${this.uri}/user/${id}`, user).toPromise();
  }
  deleteUser(id: string) {
    return this.http.delete(`${this.uri}/user/${id}`).toPromise();
  }
  getUserAcct(id: string) {
    return this.http.get<User>(`${this.uri}/account/${id}`).toPromise();
  }
  getUserAcctObs(id: string) {
    return this.http.get<User>(`${this.uri}/account/${id}`);
  }
  postBookmark(userId: string, bookmarkId: string) {
    return this.http.put<User>(`${this.uri}/add-bookmark/${userId}/${bookmarkId}`, {}).toPromise();
  }
  delBookmark(userId: string, bookmarkId: string) {
    return this.http.put<User>(`${this.uri}/del-bookmark/${userId}/${bookmarkId}`, {}).toPromise();
  }
  userForm(): FormGroup {
    return new FormGroup({
      name: new FormControl('', [
        Validators.required
      ]),
      company: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required
      ]),
      phone: new FormControl(''),
      active: new FormControl(true),
      isAdmin: new FormControl(false),
      settings: new FormGroup({
        darkMode: new FormControl(false)
      }),
      imgUrl: new FormControl(''),
      bookmarks: new FormControl([]),
      userId: new FormControl('', [
        Validators.required
      ])
    });
  }
}
 