import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sm-accordion',
  templateUrl: './sm-accordion.component.html',
  styleUrls: ['./sm-accordion.component.scss']
})
export class SmAccordionComponent implements OnInit {
  @Input() darkMode: boolean = false;
  @Input() title!: string;
  @Input() values!: any[];
  @Output() filterVals: EventEmitter<any> = new EventEmitter();
  panelStatus: boolean = true;
  constructor() { }
  swapStatus() {
    this.panelStatus = !this.panelStatus;
  }
  updateFilterVals(ev: any) {
    let sendVal = {
      checked: ev.checked,
      name: ev.source.name,
      value: ev.source.value
    }
    this.filterVals.emit(sendVal);
  }
  ngOnInit(): void {
  }

}
