<div class="home-header">
    <div class="home-header-left">
        <div class="home-header-left-title"><span class="first-k">k</span><span class="middle-text">inete</span><span class="last-k">k</span></div>
        <div class="home-header-left-subtext">learning in motion</div>
    </div>
    <div class="home-header-spacer"></div>
    <div class="home-header-right">
        <app-burger-menu lightDark="dark"></app-burger-menu>
    </div>
</div>
