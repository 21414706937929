<form [formGroup]="company" *ngIf="company" class="company-form">
    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Point of Contact</mat-label>
        <input matInput type="text" formControlName="poc">
    </mat-form-field>
    <div formGroupName="address">
        <mat-form-field appearance="fill">
            <mat-label>Street</mat-label>
            <input matInput type="text" formControlName="street">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>City</mat-label>
            <input matInput type="text" formControlName="city">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>State</mat-label>
            <input matInput type="text" formControlName="state">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>ZipCode</mat-label>
            <input matInput type="text" formControlName="zip">
        </mat-form-field>
    </div>
</form>

<button mat-button color="primary" (click)="submitCompany()">submit</button>
<button mat-button color="accent" (click)="onNoClick()">cancel</button>