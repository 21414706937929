import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Instructor } from 'src/app/models/instructor';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-instructor-image',
  templateUrl: './instructor-image.component.html',
  styleUrls: ['./instructor-image.component.scss']
})
export class InstructorImageComponent implements OnInit {
  @Input() instructor!: Instructor;
  downloadUrl!: Observable<string>;
  constructor(
    private storageService: StorageService
  ) { }
  getUrl() {
    this.downloadUrl = this.storageService.getUrlObs('user', this.instructor.instructorId);
  }
  ngOnInit(): void {
    this.getUrl();
  }

}
