<form [formGroup]="course" *ngIf="course" class="course-form">
    <mat-form-field appearance="fill">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" class="course-form-capitalize">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Platform</mat-label>
        <mat-select formControlName="platform" class="course-form-capitalize">
            <mat-option *ngFor="let platform of platforms" [value]="platform" class="course-form-capitalize">{{ platform }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Instructor</mat-label>
        <mat-select formControlName="instructor" class="course-form-capitalize">
            <mat-option *ngFor="let instructor of instructors" [value]="instructor._id" class="course-form-capitalize">{{ instructor.firstName }} {{ instructor.lastName }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="desc"></textarea>
    </mat-form-field>
    
    <label for="fileInput">Course Thumbail Image</label>
    <input type="file" (change)="handleFileInput($event)" id="fileInput">
</form>


<button mat-button color="primary" (click)="submitCourse()">submit</button>
<button mat-button color="accent" (click)="onNoClick()">cancel</button>