import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { Video } from '../../models/video';

@Component({
  selector: 'app-list-option-video',
  templateUrl: './list-option-video.component.html',
  styleUrls: ['./list-option-video.component.scss']
})
export class ListOptionVideoComponent implements OnInit {
  @Input() video!: Video;
  @Input() darkMode!: Boolean;
  downloadUrl!: Observable<string>;
  constructor(
    private storageService: StorageService
  ) { }
  getUrl() {
    this.downloadUrl = this.storageService.getUrlObs('video', this.video._id);
  }
  ngOnInit(): void {
    this.getUrl();
  }

}
