import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Account } from '../models/account';

import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url: string = 'https://learninginmotion.herokuapp.com/api';
  user$!: BehaviorSubject<User>;
  constructor(
    private http: HttpClient
  ) { }

  register(username: string, password: string) {
    return this.http.post<Account>(`${this.url}/register`, {username, password}).toPromise();
  }

  login(username: string, password: string) {
    return this.http.post(`${this.url}/login`, {username, password}).toPromise();
  }

  setSession(authResult: any) {
    const expiresAt = moment().add(authResult.exp, 'second');

    localStorage.setItem('id_token', authResult.id_token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
  }

  async isLoggedIn() {
    const status: any = await this.verifyToken();
    console.log('ran isLoggedIn', status);
    return (status.status === 0) ? false : true;
  }

  loggedInStorage() {
    return (localStorage.getItem('id_token') && localStorage.getItem('user')) ? true : false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    let expiresAt = new Date();
    if (expiration) {
      expiresAt = JSON.parse(expiration);
    }
    return moment(expiresAt);
  }

  async verifyToken() {
    try {
      let token = localStorage.getItem('id_token');
      let ret_token: any = await this.http.post(`${this.url}/tok`, { token }).toPromise();
      return { status: ret_token._id };
    } catch (error) {
      return { status: 0 };
    }
  }

  updateUser(u: User) {
    this.user$.next(u);
  }
}
