<form [formGroup]="instructor" *ngIf="instructor" class="instructor-form">
    <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput type="text" formControlName="firstName">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="lastName">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>E-Mail</mat-label>
        <input matInput type="email" formControlName="email">
    </mat-form-field>
    <div formGroupName="socials" class="instructor-form-socials">
        <mat-form-field appearance="fill">
            <mat-label>Facebook</mat-label>
            <input matInput type="url" formControlName="facebook" placeholder="https://www.facebook.com/">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>LinkedIn</mat-label>
            <input matInput type="url" formControlName="linkedin" placeholder="https://www.linkedin.com/in/">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Twitter</mat-label>
            <input matInput type="url" formControlName="twitter" placeholder="https://twitter.com/">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Instagram</mat-label>
            <input matInput type="url" formControlName="instagram" placeholder="https://www.instagram.com/">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>YouTube</mat-label>
            <input matInput type="url" formControlName="youtube" placeholder="https://www.youtube.com/">
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
        <mat-label>Bio</mat-label>
        <textarea matInput formControlName="bio"></textarea>
    </mat-form-field>

    <!-- <label for="fileInput">Instructor Profile Image</label>
    <input type="file" (change)="handleFileInput($event)" id="fileInput"> -->
    <div class="images-button-row">
        <label for="fileInput" class="images-profile-label">
            <input id="fileInput" type="file" class="images-profile-button" (change)="handleFileInput($event)"/>
            <i class="material-icons">add_a_photo</i>
        </label>
        <p class="images-profile-action"> add/change picture </p>
    </div>
</form>

<button mat-button color="primary" (click)="submitInstructor()">submit</button>
<button mat-button color="accent" (click)="onNoClick()">cancel</button>