// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCiEGbNCduBpm7822u7gFcDl8O_cjKvFJg",
    authDomain: "kin-learning.firebaseapp.com",
    projectId: "kin-learning",
    storageBucket: "kin-learning.appspot.com",
    messagingSenderId: "583289212046",
    appId: "1:583289212046:web:712ee2029e874a5b824ddf",
    measurementId: "G-GY2SZ5NGEF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
