import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kin-training-footer',
  templateUrl: './kin-training-footer.component.html',
  styleUrls: ['./kin-training-footer.component.scss']
})
export class KinTrainingFooterComponent implements OnInit {
  constructor() { }
  get thisYear() {
    return new Date().getFullYear();
  }
  ngOnInit(): void {
  }

}
