<mat-card class="course-rating" [ngClass]="{'dark': darkMode}">
    <mat-card-title class="course-rating-title">rate this course</mat-card-title>
    <mat-card-subtitle class="course-rating-subtitle">
        Help us to better create courses
    </mat-card-subtitle>
    <mat-card-content class="course-rating-rating">
        <i class="course-star-rating material-icons" (click)="rateCourse(1)">star</i>
        <i class="course-star-rating material-icons" (click)="rateCourse(2)">star</i>
        <i class="course-star-rating material-icons" (click)="rateCourse(3)">star</i>
        <i class="course-star-rating material-icons" (click)="rateCourse(4)">star</i>
        <i class="course-star-rating material-icons" (click)="rateCourse(5)">star</i>
    </mat-card-content>
</mat-card>
