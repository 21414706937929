import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-instructor-form',
  templateUrl: './admin-instructor-form.component.html',
  styleUrls: ['./admin-instructor-form.component.scss']
})
export class AdminInstructorFormComponent implements OnInit {
  instructor = new FormGroup({});
  fileToUpload!: File;
  file!: Event;
  constructor(
    public dialogRef: MatDialogRef<AdminInstructorFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  handleFileInput(event: any) {
    if (event.target.files) {
      console.log(event.target.files[0]);
      this.fileToUpload = event.target.files[0];
      this.file = event;
    } else {
      return;
    }
  }
  submitInstructor() {
    this.dialogRef.close({
      instructor: this.instructor.value,
      image: this.fileToUpload ? this.fileToUpload : null,
      file: this.file
    });
  }
  onNoClick(): void {
    this.instructor.reset();
    this.dialogRef.close('cancel');
  }
  ngOnInit(): void {
    this.instructor = this.data;
  }
}
