import { Pipe, PipeTransform } from '@angular/core';
import { noop } from 'rxjs';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(a: string): string {
    let retArr = [];
    retArr[3] = '-';
    retArr[7] = '-';
    for (let i = 0; i < a.length; i++) {
      i < 3 ? retArr[i] = a[i] : noop();
      i > 2 && i < 6 ? retArr[i + 1] = a[i] : noop();
      i > 5 ? retArr[i + 2] = a[i] : noop();
    }
    let phone = '';
    retArr.forEach(e => {
      phone += e;
    });
    return phone;
  }

}
