import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { noop } from 'rxjs';
import { User } from 'src/app/models/user';

@Component({
  selector: 'wf-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent implements OnInit {
  @Input() users!: User[];
  displayedColumns: string[] = ['name', 'company', 'email', 'phone', 'active', 'admin'];
  dataSource!: MatTableDataSource<User>;
  constructor() { }
  setDatasource() {
    this.dataSource = new MatTableDataSource<User>(this.users);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngOnInit(): void {
    this.setDatasource();
  }

}