import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Course } from '../models/course';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  private url = 'https://learninginmotion.herokuapp.com/api/';
  constructor(
    private http: HttpClient
  ) { }
  getCourses() {
    return this.http.get<Course[]>(`${this.url}courses`);
  }
  
  getCourse(id: string) {
    return this.http.get<Course>(`${this.url}course/${id}`);
  }
}
