import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { noop, Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { Video } from 'src/app/models/video';
import { AdminVideoFormComponent } from '../../components/admin-video-form/admin-video-form.component';
import { VideoService } from '../../services/video.service';
import { CoursesService } from 'src/app/services/courses.service';
import { Course } from 'src/app/models/course';
import { InstructorService } from 'src/app/services/instructor.service';
import { Instructor } from 'src/app/models/instructor';
import { CourseService } from 'src/app/services/course.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-admin-video',
  templateUrl: './admin-video.component.html',
  styleUrls: ['./admin-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminVideoComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject;
  darkMode: boolean = false;
  videos$: Observable<Video[]> = new Observable<Video[]>();
  courses!: Course[];
  private staticVideos!: Video[];
  videos!: Video[];
  instructors!: Instructor[];
  filterForm: FormGroup = new FormGroup({
    tag: new FormControl('')
  });
  constructor(
    private sharedService: SharedService,
    private videoService: VideoService,
    public dialog: MatDialog,
    private coursesService: CoursesService,
    private instructorService: InstructorService,
    private courseService: CourseService,
    private storageService: StorageService
  ) { }
  getSettings() {
    this.sharedService.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: boolean) => {
      this.darkMode = data;
    }, err => console.log(err), () => console.log('done'));
  }
  getVideos() {
    this.videos$ = this.videoService.getVideos();
    this.videoService.getVideos().pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: Video[]) => {
        this.staticVideos = data;
        this.videos = this.staticVideos;
      }, err => console.log(err), () => console.log('done'));
  }
  getCourses() {
    this.coursesService.getCourses().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: Course[]) => {
      this.courses = data;
    }, err => console.log(err), () => console.log('done'));
  }
  getInstructors() {
    this.instructorService.getInstructors().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: Instructor[]) => {
      this.instructors = data;
    }, err => console.log(err), () => console.log('done'));
  }
  async addVideo(video: Video, file: any) {
    let posted = await this.videoService.addVideo(video);
    if (posted._id) {
      this.postImage(posted._id, file);
      let updated = await this.courseService.addVideo(posted.course as unknown as string, posted._id);
    }
    this.getVideos();
  }
  async updateVideo(id: string, video: Video) {
    let updated = await this.videoService.updateVideo(id, video);
    this.getVideos();
  }
  async deleteVideo(id: string) {
    let deleted = await this.videoService.deleteVideo(id);
    this.getVideos();
  }
  async postImage(id: string, file: any) {
    await this.storageService.uploadFile(file, 'video', id);
  }
  crudVideo(action: string, video?: Video) {
    let vidForm = video ? this.videoService.createVideoForm(video) : this.videoService.createVideoForm();
    video ? vidForm.patchValue({
      course: video.course._id,
      instructor: video.instructor._id
    }) : noop();
    let dialog = this.dialog.open(AdminVideoFormComponent, {
      backdropClass: 'video-dialog-background',
      panelClass: 'video-dialog-container',
      data: {
        videoForm: vidForm,
        courses: this.courses,
        instructors: this.instructors,
        platforms: this.sharedService.platforms
      }
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (action === 'post' && data) {
          this.addVideo(data.video.value, data.image);
        } else if (action === 'put' && video && data) {
          vidForm.patchValue(data.video.value);
          this.updateVideo(video._id, vidForm.value);
          data.file ? this.postImage(video._id, data.file) : noop();
        }
      }, err => console.log(err), () => console.log('done'));
  }
  watchFilter() {
    this.filterForm.controls.tag.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(1000)
      )
      .subscribe((d) => {
        this.filterTags(d);
      });
  }
  filterTags(criteria: string) {
    const starter = this.staticVideos;
    if (criteria) {
      let filtered = starter.filter((data) => {
        return data.tags.indexOf(criteria) >= 0;
      });
      this.videos = filtered;
    } else {
      this.videos = starter;
    }
    

  }
  ngOnInit(): void {
    this.getSettings();
    this.getVideos();
    this.getCourses();
    this.getInstructors();
    this.watchFilter();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}