<div class="kin-training-footer">
    <div class="footer">
        <div class="footer-row--1">
            <img src="../../../assets/images/KLiM-logo-white.svg" alt="Kinetek Learning in Motion Logo" class="footer-logo">
            <h3 class="footer-title">Learning <span class="contrast-color-blue">in</span> Motion</h3>
        </div>
        <div class="footer-row--2">
            <div class="footer-col footer-col--1">
                <h4 class="footer-col-header">Site Tree</h4>
                <ul class="footer-col-list">
                    <li class="footer-col-list-item">
                        <a routerLink="/" class="footer-col-link">Home</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a routerLink="/learning/courses" class="footer-col-link">Courses</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a routerLink="/learning/instructor" class="footer-col-link">Instructor</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a routerLink="/learning/user" class="footer-col-link">User</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a routerLink="/admin" class="footer-col-link">Administration</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col footer-col--2">
                <h4 class="footer-col-header">Reference</h4>
                <ul class="footer-col-list">
                    <li class="footer-col-list-item">
                        <a href="http://www.vimeo.com" target="_blank" class="footer-col-link">Vimeo</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="http://firebase.google.com" target="_blank" class="footer-col-link">Firebase</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col footer-col--3">
                <h4 class="footer-col-header">Software</h4>
                <ul class="footer-col-list">
                    <li class="footer-col-list-item">
                        <a href="https://www.ibm.com/products/cognos-analytics" target="_blank" class="footer-col-link">IBM Cognos</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="https://www.deltek.com/en/products/project-erp/costpoint" target="_blank" class="footer-col-link">Deltek Costpoint</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="https://kinetek.com/solutions/kpp" target="_blank" class="footer-col-link">Kinetek KPP</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col footer-col--4">
                <h4 class="footer-col-header">Company</h4>
                <ul class="footer-col-list">
                    <li class="footer-col-list-item">
                        <a href="http://www.kinetek.com/about-us" target="_blank" class="footer-col-link">About Us</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="http://www.kinetek.com/solutions" target="_blank" class="footer-col-link">Solutions</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="http://www.kinetek.com/success-stories" target="_blank" class="footer-col-link">Success Stories</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="http://www.kinetek.com/media" target="_blank" class="footer-col-link">Media</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="http://www.kinetek.com/blog" target="_blank" class="footer-col-link">Blog</a>
                    </li>
                    <li class="footer-col-list-item">
                        <a href="http://www.kinetek.com/contact" target="_blank" class="footer-col-link">Contact</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col footer-col--5">
                <div class="footer-col-contact">
                    <div class="contact-card">
                        <a href="tel:18885463835" class="contact-card-link">1.888.KINETEK</a>
                        <a href="mailto:contact@kinetek.com" class="contact-card-link">contact@kinetek.com</a>
                        <p></p>
                        <div>12120 Sunset Hills Rd</div>
                        <div>Reston, VA 20190</div>
                    </div>
                    <div class="footer-col-contact-socials">
                        <a href="https://twitter.com/kinetekcons" target="_blank" class="socials-link"><i class="fab fa-twitter"></i></a>
                        <a href="https://www.linkedin.com/company/kinetek-consulting/" target="_blank" class="socials-link"><i class="fab fa-linkedin-in"></i></a>
                        <a href="https://www.youtube.com/channel/UC1ZlP5O_WnZEVcDyAhJDrmw" target="_blank" class="socials-link"><i class="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-row--3">
            <div class="footer-row--3-left">Copyright &copy; {{ thisYear }} Kinetek. All rights reserved.</div>
            <div class="footer-row--3-right">
                <a href="https://kinetek.com/privacy-policy" class="footer-legal-link" target="_blank">Privacy Policy</a>
                <a routerLink="/terms" class="footer-legal-link">Terms & Conditions</a>
            </div>
        </div>
    </div>
</div>
