import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() slides: any;
  currentSlide = 1;
  constructor() { }
  tester(direction: string) {
    direction === 'right' ? this.currentSlide++ : this.currentSlide--;
    if (this.currentSlide > 3) {
      this.currentSlide = 1;
    }
    if (this.currentSlide < 1) {
      this.currentSlide = 1;
    }
  }
  ngOnInit(): void {
    setInterval(() => {
      this.tester('right');
    }, 10000);
  }

}
