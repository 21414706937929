<ng-container *ngIf="video">
    <div class="kin-video-container">
        <!-- <app-kin-video-player [video]="video"></app-kin-video-player> -->
        <div class="kin-video">
            <iframe
                [src]="videoUrl"
                frameborder="0"
                allow="fullscreen"
                allowfullscreen
                class="kin-video-player"
            >
            </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
</ng-container>


<!-- <div style="padding:56.25% 0 0 0;position:relative;">
    <iframe
        src="https://player.vimeo.com/video/194555118"
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        frameborder="0" allow="autoplay; fullscreen"
        allowfullscreen>
    </iframe>
</div>
<script src="https://player.vimeo.com/api/player.js"></script> -->