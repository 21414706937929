import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() darkMode!: boolean;
  @Output() rate: EventEmitter<number> = new EventEmitter();
  constructor() { }
  rateCourse(rating: number) {
    this.rate.emit(rating);
  }
  ngOnInit(): void {
  }

}
