import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Course } from 'src/app/models/course';
import { HTMLInputEvent } from 'src/app/models/htmlinput-event';
import { Instructor } from 'src/app/models/instructor';

@Component({
  selector: 'app-admin-video-form',
  templateUrl: './admin-video-form.component.html',
  styleUrls: ['./admin-video-form.component.scss']
})
export class AdminVideoFormComponent implements OnInit {
  video = new FormGroup({});
  courses!: Course[];
  instructors!: Instructor[];
  platforms!: string[];
  fileToUpload!: File;
  file!: Event;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    public dialogRef: MatDialogRef<AdminVideoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  get tags(): string[] {
    return this.video.get('tags')?.value;
  }
  get keyTopics(): string[] {
    return this.video.get('keyTopics')?.value;
  }
  addTag(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push(value);

      if (input) {
        input.value = '';
      }
    }
  }
  removeTag(tag: string) {
    let pos = this.tags.indexOf(tag);
    this.tags.splice(pos, 1);
  }
  addKeyTopic(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.keyTopics.push(value);

      if (input) {
        input.value = '';
      }
    }
  }
  removeKeyTopic(keyTopic: string) {
    let pos = this.keyTopics.indexOf(keyTopic);
    this.keyTopics.splice(pos, 1);
  }
  handleFileInput(event: any) {
    if (event.target.files) {
      console.log(event.target.files[0]);
      this.fileToUpload = event.target.files[0];
      this.file = event;
    } else {
      return;
    }
  }
  submitVideo() {
    this.dialogRef.close({
      video: this.video,
      image: this.fileToUpload,
      file: this.file
    });
  }
  onNoClick(): void {
    this.video.reset();
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.video = this.data.videoForm;
    this.courses = this.data.courses;
    this.instructors = this.data.instructors;
    this.platforms = this.data.platforms;
    console.log(this.video);
    console.log(this.data);
  }

}
