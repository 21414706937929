<div *ngIf="courses$ | async as courses" class="admin-course">
    <mat-card *ngFor="let course of courses" class="admin-course-card" [ngClass]="{'dark': darkMode}">
        <app-course-image [course]="course"></app-course-image>
        <mat-card-header class="admin-course-card-header">
            <mat-card-title class="admin-course-card-title">{{ course.title }}</mat-card-title>
            <mat-card-subtitle class="admin-course-card-subtitle">{{ course.platform }}</mat-card-subtitle>
        </mat-card-header>
        <!-- <mat-card-content></mat-card-content> -->
        <mat-card-actions class="admin-course-card-actions">
            <button mat-button (click)="crudCourse('put', course)">edit</button>
            <button mat-button color="warn" (click)="deleteCourse(course)">delete</button>
        </mat-card-actions>
    </mat-card>
    <div class="admin-action-row">
        <button mat-fab color="accent" class="admin-add-button" (click)="crudCourse('post')">
            <i class="fas fa-plus admin-course-add"></i>
        </button>
    </div>
</div>