import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Course } from 'src/app/models/course';
import { Video } from '../models/video';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  url: string = 'https://learninginmotion.herokuapp.com/api/course';
  extUri: string = 'https://learninginmotion.herokuapp.com/api/cross/course';
  rateUri: string = 'https://learninginmotion.herokuapp.com/api/rating/course'
  constructor(
    private http: HttpClient
  ) { }
  getCourseForm() {
    return new FormGroup({
      courseId: new FormControl(''),
      title: new FormControl(''),
      platform: new FormControl(''),
      desc: new FormControl(''),
      videos: new FormControl([]),
      rating: new FormControl(''),
      instructor: new FormControl([]),
      tags: new FormControl([]),
      updated: new FormControl(new Date())
    });
  }
  getCourses() {
    return this.http.get<Course[]>(`${this.url}s`);
  }
  addCourse(course: Course) {
    return this.http.post<Course>(this.url, course).toPromise();
  }
  putCourse(id: string, course: Course) {
    return this.http.put(`${this.url}/${id}`, course).toPromise();
  }
  deleteCourse(id: string) {
    return this.http.delete(`${this.url}/${id}`).toPromise();
  }
  addVideo(id: string, vidId: string) {
    return this.http.put<Course>(`${this.extUri}/${id}/${vidId}`, {}).toPromise();
  }
  rateCourse(id: string, score: number) {
    return this.http.put<Course>(`${this.rateUri}/${id}/${score}`, {}).toPromise();
  }
}
