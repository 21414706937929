import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-video-titlebar',
  templateUrl: './video-titlebar.component.html',
  styleUrls: ['./video-titlebar.component.scss']
})
export class VideoTitlebarComponent implements OnInit {
  @Input() videoTitle!: string;
  @Input() toggleChecked!: boolean;
  @Output() drawer: EventEmitter<any> = new EventEmitter();
  constructor() { }
  drawerToggle() {
    this.drawer.emit('drawerToggle');
  }
  ngOnInit(): void {
  }

}
