<div class="course" [ngClass]="{'dark': darkMode}">
  <ng-container *ngIf="getCourseId(activatedRoute) === 'favorites'">
    <mat-drawer-container class="drawer-container" *ngIf="user$ | async as user">
      <mat-drawer #drawer mode="side" class="drawer-container-drawer" opened [ngClass]="{'dark': darkMode}">
        <div class="inner-container" [ngClass]="{'dark': darkMode}">
          <div class="inner-container-title">favorite videos</div>
          <mat-divider></mat-divider>
          <mat-selection-list #vids [multiple]="false" class="drawer-container-drawer-list">
            <mat-list-option class="drawer-list-option" *ngFor="let video of user.bookmarks; let i = index" [value]="i" (click)="setVideo(video)">
              <div class="selected-cover">
                <div class="selected-cover-text">now playing</div>
              </div>
              <app-list-option-video [video]="video" [darkMode]="darkMode"></app-list-option-video>
            </mat-list-option>
          </mat-selection-list>
        </div>
      </mat-drawer>
      <mat-drawer-content class="drawer-container-content" [ngClass]="{'dark': darkMode}">
        <app-course-titlebar courseTitle="favorites" *ngIf="!currentVideo"></app-course-titlebar>
        <app-video-titlebar *ngIf="currentVideo" [videoTitle]="currentVideo.title" [toggleChecked]="drawer.opened" (drawer)="drawer.toggle()"></app-video-titlebar>
        <app-kin-video [video]="currentVideo"></app-kin-video>
        <div class="drawer-container-content-details" *ngIf="currentVideo">
          <app-video-header *ngIf="currentVideo" [currentVideo]="currentVideo" [darkMode]="darkMode"></app-video-header>
          <mat-divider></mat-divider>
          <app-video-description
            *ngIf="currentVideo"
            [currentVideo]="currentVideo"
            [darkMode]="darkMode"
            (instructor)="openProfile($event)"></app-video-description>
          <mat-divider></mat-divider>
          <br>
        </div>
        <br>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-container>
  <mat-drawer-container class="drawer-container" *ngIf="course$ | async as course">
      <mat-drawer #drawer mode="side" class="drawer-container-drawer" opened [ngClass]="{'dark': darkMode}">
        <div class="inner-container" [ngClass]="{'dark': darkMode}">
          <div class="inner-container-title">{{ course.title }} videos</div>
          <mat-divider></mat-divider>
          <mat-selection-list #vids [multiple]="false" class="drawer-container-drawer-list">
            <mat-list-option class="drawer-list-option" *ngFor="let video of course.videos; let i = index" [value]="i" (click)="setVideo(video)">
              <div class="selected-cover">
                <div class="selected-cover-text">now playing</div>
              </div>
              <app-list-option-video [video]="video" [darkMode]="darkMode"></app-list-option-video>
            </mat-list-option>
          </mat-selection-list>
        </div>
      </mat-drawer>
      <mat-drawer-content class="drawer-container-content" [ngClass]="{'dark': darkMode}">
        <app-course-titlebar [courseTitle]="course.title" *ngIf="!currentVideo"></app-course-titlebar>
        <app-rating *ngIf="!currentVideo && course" (rate)="rateCourse(course._id, $event)" [darkMode]="darkMode"></app-rating>

        <app-video-titlebar *ngIf="currentVideo" [videoTitle]="currentVideo.title" [toggleChecked]="drawer.opened" (drawer)="drawer.toggle()"></app-video-titlebar>
        <app-kin-video [video]="currentVideo"></app-kin-video>
        <div class="drawer-container-content-details" *ngIf="currentVideo">
          <app-video-header *ngIf="currentVideo" [currentVideo]="currentVideo" [darkMode]="darkMode"></app-video-header>
          <mat-divider></mat-divider>
          <app-video-description
            *ngIf="currentVideo"
            [currentVideo]="currentVideo"
            [darkMode]="darkMode"
            (instructor)="openProfile($event)"></app-video-description>
          <mat-divider></mat-divider>
          <br>
        </div>
        <br>
      </mat-drawer-content>
  </mat-drawer-container>
</div>
