import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  unsubscribe$ = new Subject;
  darkMode: boolean = false;
  listItems = [
    { name: 'instructors', icon: 'fa-chalkboard-teacher', slug: '/admin/instructors' },
    { name: 'companies', icon: 'fa-building', slug: '/admin/companies' },
    { name: 'courses', icon: 'fa-chalkboard', slug: '/admin/courses' },
    { name: 'videos', icon: 'fa-video', slug: '/admin/videos' },
    { name: 'users', icon: 'fa-users', slug: '/admin/users' }
  ];
  constructor(
    private sharedService: SharedService
  ) { }
  getSettings() {
    this.sharedService.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data) => {
      this.darkMode = data;
    }, err => console.log(err), () => console.log('done'));
  }
  ngOnInit(): void {
    this.getSettings();
  }

}
