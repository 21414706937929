<div class="register">
    <form [formGroup]="form" class="register-form">
        <img src="../../../assets/images/KLiM-logo.svg" alt="KLiM Logo" class="form-klim-logo">
        <h1>sign up</h1>
        <mat-form-field appearance="fill" class="register-form-field">
            <mat-label class="register-form-field-label">E-mail</mat-label>
            <input type="email" matInput formControlName="username" class="register-form-field-input">
        </mat-form-field>
        <mat-form-field appearance="fill" class="register-form-field">
            <mat-label class="register-form-field-label">Password</mat-label>
            <input type="password" matInput formControlName="password" class="register-form-field-input">
        </mat-form-field>
        <mat-form-field appearance="fill" class="register-form-field">
            <mat-label class="register-form-field-label">Name</mat-label>
            <input type="text" matInput formControlName="name" class="register-form-field-input">
        </mat-form-field>
        <mat-form-field appearance="fill" class="register-form-field company-options" *ngIf="companies$ | async as companies">
            <mat-label class="register-form-field-label">Company</mat-label>
            <mat-select formControlName="company" class="register-form-field-input">
                <mat-option *ngFor="let comp of companies" [value]="comp._id" class="company-options">{{ comp.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button
            mat-flat-button
            color="primary"
            type="submit"
            (click)="addNew()"
            class="register-form-button-submit"
            [disabled]="!form.valid"
        >register</button>
        <div class="register-form-bottom">
            <span>already have an account?</span>
            <a routerLink="/login" class="register-form-bottom-signup">Sign in</a>
        </div>
    </form>
</div>