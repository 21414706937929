import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './containers/about/about.component';
import { AdminCompanyComponent } from './containers/admin-company/admin-company.component';
import { AdminCourseComponent } from './containers/admin-course/admin-course.component';
import { AdminInstructorComponent } from './containers/admin-instructor/admin-instructor.component';
import { AdminUserComponent } from './containers/admin-user/admin-user.component';
import { AdminVideoComponent } from './containers/admin-video/admin-video.component';
import { AdminComponent } from './containers/admin/admin.component';
import { InstructorComponent } from './containers/instructor/instructor.component';
import { CourseComponent } from './containers/course/course.component';
import { CoursesComponent } from './containers/courses/courses.component';
import { TrainingComponent } from './containers/training/training.component';
import { UserComponent } from './containers/user/user.component';
import { LearningComponent } from './containers/learning/learning.component';
import { HomeComponent } from './containers/home/home.component';
import { LoginComponent } from './containers/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { RegisterComponent } from './containers/register/register.component';
import { FourohfourComponent } from './containers/fourohfour/fourohfour.component';
import { HelpComponent } from './containers/help/help.component';
import { PrivacyComponent } from './containers/privacy/privacy.component';
import { TermsComponent } from './containers/terms/terms.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'learning', component: LearningComponent, canActivate: [AuthGuard] , children: [
    { path: 'courses', component: CoursesComponent },
    { path: 'course/:courseId', component: CourseComponent },
    { path: 'instructor', component: InstructorComponent },
    { path: 'user', component: UserComponent }
    
  ]},
  { path: 'admin', component: AdminComponent, children: [
    { path: 'instructors', component: AdminInstructorComponent },
    { path: 'companies', component: AdminCompanyComponent },
    { path: 'courses', component: AdminCourseComponent },
    { path: 'videos', component: AdminVideoComponent },
    { path: 'users', component: AdminUserComponent }
  ] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'help', component: HelpComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: '', component: HomeComponent },
  { path: '**', component: FourohfourComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
