import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Course } from '../../models/course';
import { Video } from '../../models/video';
import { CoursesService } from '../../services/courses.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  unsubscribe$!: Subject<any>;
  course$!: Observable<Course>;
  courses$!: Observable<Course[]>;
  currentVideo!: Video;
  darkMode: boolean = false;
  constructor(
    private courseService: CoursesService,
    private router: ActivatedRoute
  ) { }
  getCourseId(a: ActivatedRoute) {
    if (a.snapshot.params.courseId) {
      return a.snapshot.params.courseId;
    } else {
      return null;
    }
  }
  getCourse(courseId:string) {
    if (courseId) {
      this.course$ = this.courseService.getCourse(courseId);
    }
  }
  getCourses() {
    const courseId = this.getCourseId(this.router);
    if (courseId) {
      this.getCourse(courseId);
      return;
    }
    this.courses$ = this.courseService.getCourses();
  }
  switchDarkMode() {
    this.darkMode = !this.darkMode;
  }
  setVideo(vid: Video) {
    this.currentVideo = vid;
  }
  ngOnInit(): void {
    this.getCourses();
  }

}