<div *ngIf="instructors$" class="admin-instructor">
    <mat-card *ngFor="let instructor of instructors$" class="admin-instructor-card" [ngClass]="{'dark': darkMode}">
        <!-- <img [src]="getUrl(instructor) | async" alt="Profile Image" class="admin-instructor-card-thumbnail" loading="lazy"> -->
        <app-instructor-image [instructor]="instructor"></app-instructor-image>
        <mat-card-header>
            <mat-card-title class="admin-instructor-card-title">{{ instructor.firstName }} {{ instructor.lastName }}</mat-card-title>
            <mat-card-subtitle class="admin-instructor-card-subtitle">{{ instructor.title }}</mat-card-subtitle>
        </mat-card-header>
        <!-- <mat-card-content></mat-card-content> -->
        <mat-divider></mat-divider>
        <mat-card-actions class="admin-instructor-card-actions">
            <button mat-button (click)="crudInstructor('put', instructor)">edit</button>
            <button mat-button color="warn" (click)="deleteInstructor(instructor)">delete</button>
        </mat-card-actions>
    </mat-card>
    <div class="admin-action-row">
        <button mat-fab color="accent" class="admin-add-button" (click)="crudInstructor('post')">
            <i class="fas fa-plus admin-instructor-add"></i>
        </button>
    </div>
</div>