import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { noop, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { Course } from 'src/app/models/course';
import { AdminCourseFormComponent } from '../../components/admin-course-form/admin-course-form.component';
import { CourseService } from '../../services/course.service';
import { Instructor } from 'src/app/models/instructor';
import { InstructorService } from 'src/app/services/instructor.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-admin-course',
  templateUrl: './admin-course.component.html',
  styleUrls: ['./admin-course.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminCourseComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject;
  darkMode: boolean = false;
  courses$!: Observable<Course[]>;
  instructors$!: Instructor[];
  courseForm: FormGroup = new FormGroup({
    courseId: new FormControl(''),
    title: new FormControl(''),
    platform: new FormControl(''),
    desc: new FormControl(''),
    videos: new FormControl([]),
    rating: new FormControl(''),
    instructor: new FormControl([]),
    tags: new FormControl([]),
    updated: new FormControl(new Date())
  });
  constructor(
    private sharedService: SharedService,
    private courseService: CourseService,
    public dialog: MatDialog,
    private instructorService: InstructorService,
    private storageService: StorageService
  ) { }
  getSettings() {
    this.sharedService.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: boolean) => {
      this.darkMode = data;
    }, err => console.log(err), () => console.log('done'));
  }
  crudCourse(action: string, course?: Course) {
    course ? this.courseForm.patchValue(course) : noop();
    let dialog = this.dialog.open(AdminCourseFormComponent, {
      backdropClass: 'course-form-dialog-background',
      panelClass: 'course-form-dialog-container',
      data: {
        course: this.courseForm,
        instructors: this.instructors$,
        platforms: this.sharedService.platforms
      }
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (action === 'post' && data) {
          this.postCourse(data.course.value, data.file);
        } else if (action === 'put' && course && data && course._id) {
          let currentInstructor = course.instructor.toString();
          this.courseForm.patchValue(data.course.value);
          this.putCourse(course._id, this.courseForm.value);
          data.instructor ? this.addCourseToInstructor(course._id, this.courseForm.value.instructor) : noop();
          currentInstructor !== this.courseForm.value.instructor ? this.remCourseFromInstructor(course._id, currentInstructor) : noop();
          data.file ? this.postImage(course._id, data.file) : noop();
        } else {
          this.courseForm.reset();
        }
      }, err => console.log(err), () => console.log('done'));
  }
  getCourses() {
    this.courses$ = this.courseService.getCourses();
  }
  getInstructors() {
    this.instructorService.getInstructors().pipe(
      takeUntil(
        this.unsubscribe$
      )
    ).subscribe((data: Instructor[]) => {
      this.instructors$ = data;
    }, err => console.log(err), () => console.log('data'));
  }
  async postCourse(course: Course, file: any) {
    course.rating = {
      score: 0,
      ratings: 0
    }
    let poster = await this.courseService.addCourse(course);
    if (poster._id) {
      let updated = await this.addCourseToInstructor(poster._id, this.courseForm.value.instructor);
    }
    if (poster._id) {
      this.postImage(poster._id, file);
    }
    this.resetForm();
    this.getCourses();
  }
  async deleteCourse(course: Course) {
    if (course._id) {
      let deleted = await this.courseService.deleteCourse(course._id);
    }
    this.getCourses();
  }
  async putCourse(id: string, course: Course) {
    let putted = await this.courseService.putCourse(id, course);
    this.resetForm();
    this.getCourses();
  }
  postImage(id: string, file: any) {
    this.storageService.uploadFile(file, 'course', id);
  }
  async addCourseToInstructor(courseId: string, instructorId: string) {
    let putted = await this.instructorService.addCourse(instructorId, courseId);
  }
  async remCourseFromInstructor(courseId: string, instructorId: string) {
    let putted = await this.instructorService.removeCourse(instructorId, courseId);
  }
  resetForm() {
    this.courseForm.reset();
  }
  ngOnInit(): void {
    this.getSettings();
    this.getCourses();
    this.getInstructors();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
