<div class="help-page">
    <div class="help-page-left">
      <h2>Have a question?</h2>
        <form [formGroup]="questionForm" *ngIf="questionForm">
          <mat-form-field appearance="fill" class="help-form-field">
            <mat-label>Select a Category</mat-label>
            <mat-select formControlName="category">
              <mat-option *ngFor="let category of categories" [value]="category">
                {{ category | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="help-form-field">
            <mat-label>Question</mat-label>
            <input matInput formControlName="question">
          </mat-form-field>

          <button type="submit" mat-flat-button color="primary" (click)="addQuestion(questionForm.value)" class="question-button">Submit</button>
        </form>
    </div>
    <div class="help-page-right">
        <h2>Frequently Asked Questions</h2>
        <h5>Looking for your question? Only answered questions will appear, check back soon!</h5>
        <mat-accordion *ngIf="questions$ | async as questions">
          
          <ng-container *ngFor="let question of questions">
            <mat-expansion-panel *ngIf="question.answer">
              <mat-expansion-panel-header>
                <mat-panel-title class="help-title">
                  {{ question.category }}
                </mat-panel-title>
                <mat-panel-description class="help-description">
                  {{ question.question }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p class="help-answer">{{ question.answer }}</p>
            </mat-expansion-panel>
          </ng-container>
            
        </mat-accordion>
    </div>
</div>
<app-kin-training-footer></app-kin-training-footer>