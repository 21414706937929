import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-company-form',
  templateUrl: './admin-company-form.component.html',
  styleUrls: ['./admin-company-form.component.scss']
})
export class AdminCompanyFormComponent implements OnInit {
  company = new FormGroup({});
  constructor(
    public dialogRef: MatDialogRef<AdminCompanyFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  submitCompany() {
    this.dialogRef.close(this.company);
  }
  onNoClick(): void {
    this.company.reset();
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.company = this.data;
  }

}
