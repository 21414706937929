import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { Instructor } from '../../models/instructor';

@Component({
  selector: 'app-instructor-profile',
  templateUrl: './instructor-profile.component.html',
  styleUrls: ['./instructor-profile.component.scss']
})
export class InstructorProfileComponent implements OnInit {
  profile!: Instructor;
  showExpanded: boolean = false;
  downloadUrl!: Observable<string>;
  constructor(
    public dialogRef: MatDialogRef<InstructorProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService
  ) { }
  expand() {
    this.showExpanded = !this.showExpanded;
  }

  async getUrl(userId: string) {
    this.downloadUrl = this.storageService.getUrlObs('user', userId);
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.profile = this.data;
    console.log(this.profile);
    this.getUrl(this.profile.instructorId);
  }

}
