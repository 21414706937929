<div class="kin-training" [ngClass]="{'dark': darkMode}">
  <!-- <app-kin-training-navbar (darkMode)="switchDarkMode()"></app-kin-training-navbar> -->
  <router-outlet></router-outlet>
  <!-- <mat-drawer-container class="drawer-container" *ngIf="course$ | async as course">
    <mat-drawer #drawer mode="side" class="drawer-container-drawer" opened [ngClass]="{'dark': darkMode}">
      <div class="inner-container" [ngClass]="{'dark': darkMode}">
        <div class="inner-container-title">{{ course.title }} videos</div>
        <mat-divider></mat-divider>
        <mat-selection-list #vids [multiple]="false" class="drawer-container-drawer-list">
          <mat-list-option class="drawer-list-option" *ngFor="let video of course.videos; let i = index" [value]="i" (click)="setVideo(video)">
            <app-list-option-video [video]="video" [darkMode]="darkMode"></app-list-option-video>
          </mat-list-option>
        </mat-selection-list>
        <div class="drawer-spacer"></div>
        <div class="drawer-container-drawer-footer">build version: 1.0.0</div>
      </div>
    </mat-drawer>
    <mat-drawer-content class="drawer-container-content" [ngClass]="{'dark': darkMode}">
      <app-video-titlebar *ngIf="currentVideo" [videoTitle]="currentVideo.title" [toggleChecked]="drawer.opened" (drawer)="drawer.toggle()"></app-video-titlebar>
      <app-kin-video [video]="currentVideo"></app-kin-video>
      <div class="drawer-container-content-details" *ngIf="currentVideo">
        <app-video-header *ngIf="currentVideo" [currentVideo]="currentVideo"></app-video-header>
        <mat-divider></mat-divider>
        <app-video-description *ngIf="currentVideo" [currentVideo]="currentVideo" [darkMode]="darkMode"></app-video-description>
        <mat-divider></mat-divider>
      </div>
      <br>
    </mat-drawer-content>
  </mat-drawer-container> -->
</div>