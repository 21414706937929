import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';

import { SharedService } from './services/shared.service';
import { InstructorProfileComponent } from './components/instructor-profile/instructor-profile.component';
import { AdminInstructorFormComponent } from './components/admin-instructor-form/admin-instructor-form.component';
import { AdminCompanyFormComponent } from './components/admin-company-form/admin-company-form.component';
import { AboutComponent } from './containers/about/about.component';
import { AdminComponent } from './containers/admin/admin.component';
import { AdminCompanyComponent } from './containers/admin-company/admin-company.component';
import { AdminCourseComponent } from './containers/admin-course/admin-course.component';
import { AdminInstructorComponent } from './containers/admin-instructor/admin-instructor.component';
import { AdminUserComponent } from './containers/admin-user/admin-user.component';
import { AdminVideoComponent } from './containers/admin-video/admin-video.component';
import { CourseComponent } from './containers/course/course.component';
import { CoursesComponent } from './containers/courses/courses.component';
import { InstructorComponent } from './containers/instructor/instructor.component';
import { TrainingComponent } from './containers/training/training.component';
import { UserComponent } from './containers/user/user.component';
import { AdminCourseFormComponent } from './components/admin-course-form/admin-course-form.component';
import { AdminSubnavComponent } from './components/admin-subnav/admin-subnav.component';
import { AdminSubnavListItemComponent } from './components/admin-subnav-list-item/admin-subnav-list-item.component';
import { AdminVideoFormComponent } from './components/admin-video-form/admin-video-form.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { KinTrainingNavbarComponent } from './containers/kin-training-navbar/kin-training-navbar.component';
import { KinVideoComponent } from './components/kin-video/kin-video.component';
import { ListOptionCourseComponent } from './components/list-option-course/list-option-course.component';
import { ListOptionVideoComponent } from './components/list-option-video/list-option-video.component';
import { MenuScreenComponent } from './components/menu-screen/menu-screen.component';
import { SmAccordionComponent } from './components/sm-accordion/sm-accordion.component';
import { VideoHeaderComponent } from './components/video-header/video-header.component';
import { VideoTitlebarComponent } from './components/video-titlebar/video-titlebar.component';
import { VideoDescriptionComponent } from './components/video-description/video-description.component';
import { AdminUserFormComponent } from './components/admin-user-form/admin-user-form.component';
import { MatTableComponent } from './components/mat-table/mat-table.component';
import { PhonePipe } from './pipes/phone.pipe';
import { LearningComponent } from './containers/learning/learning.component';
import { LegaleseFooterComponent } from './components/legalese-footer/legalese-footer.component';
import { RatingComponent } from './components/rating/rating.component';
import { CourseTitlebarComponent } from './components/course-titlebar/course-titlebar.component';
import { TrCardComponent } from './components/tr-card/tr-card.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { PricingComponent } from './containers/pricing/pricing.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { HomeComponent } from './containers/home/home.component';
import { InViewDirective } from './directives/in-view.directive';
import { NasbaComponent } from './containers/nasba/nasba.component';
import { LoginComponent } from './containers/login/login.component';
import { AuthService } from './services/auth.service';
// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { InstructorImageComponent } from './containers/instructor-image/instructor-image.component';
import { CourseImageComponent } from './containers/course-image/course-image.component';
import { VideoImageComponent } from './containers/video-image/video-image.component';
import { RegisterComponent } from './containers/register/register.component';
import { KinTrainingFooterComponent } from './containers/kin-training-footer/kin-training-footer.component';
import { FourohfourComponent } from './containers/fourohfour/fourohfour.component';
import { HelpComponent } from './containers/help/help.component';
import { PrivacyComponent } from './containers/privacy/privacy.component';
import { TermsComponent } from './containers/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    AdminComponent,
    AdminCompanyComponent,
    AdminCourseComponent,
    AdminInstructorComponent,
    AdminUserComponent,
    AdminVideoComponent,
    CourseComponent,
    CoursesComponent,
    InstructorComponent,
    TrainingComponent,
    UserComponent,
    AdminCompanyFormComponent,
    AdminCourseFormComponent,
    AdminInstructorFormComponent,
    AdminSubnavComponent,
    AdminSubnavListItemComponent,
    AdminVideoFormComponent,
    BurgerMenuComponent,
    InstructorProfileComponent,
    KinTrainingNavbarComponent,
    KinVideoComponent,
    ListOptionCourseComponent,
    ListOptionVideoComponent,
    MenuScreenComponent,
    SmAccordionComponent,
    VideoDescriptionComponent,
    VideoHeaderComponent,
    VideoTitlebarComponent,
    AdminUserFormComponent,
    MatTableComponent,
    PhonePipe,
    LearningComponent,
    LegaleseFooterComponent,
    RatingComponent,
    CourseTitlebarComponent,
    TrCardComponent,
    CarouselComponent,
    PricingComponent,
    HomeHeaderComponent,
    HomeComponent,
    InViewDirective,
    NasbaComponent,
    LoginComponent,
    InstructorImageComponent,
    CourseImageComponent,
    VideoImageComponent,
    RegisterComponent,
    KinTrainingFooterComponent,
    FourohfourComponent,
    HelpComponent,
    PrivacyComponent,
    TermsComponent
  ],
  entryComponents: [
    InstructorProfileComponent,
    AdminInstructorFormComponent,
    AdminCompanyFormComponent,
    AdminCourseFormComponent,
    AdminUserFormComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule
  ],
  providers: [SharedService],
  bootstrap: [AppComponent]
})
export class AppModule { }