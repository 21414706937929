<div class="drawer-container-content-titlebar">
  <div class="video-titlebar-left">
    <nav class="video-titlebar-breadcrumbs">
      <a routerLink="/" class="video-titlebar-breadcrumbs-links">
          <mat-icon>home</mat-icon>
          <span class="link-span">home</span>
      </a>
      <mat-icon>chevron_right</mat-icon>
      <a routerLink="../../courses" class="video-titlebar-breadcrumbs-links">
          <mat-icon>school</mat-icon>
          <span class="link-span">courses</span>
      </a>
    </nav>
    <mat-slide-toggle
      #sideNav
      color="warn"
      checked="toggleChecked"
      disabled="false"
      labelPosition="after"
      class="side-menu-toggle"
      (click)="drawerToggle()">
      show side menu
    </mat-slide-toggle>
  </div>
  
  <div class="titlebar-spacer"></div>
  <h1 *ngIf="videoTitle" class="current-video-title">{{ videoTitle }}</h1>
</div>