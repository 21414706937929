import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fourohfour',
  templateUrl: './fourohfour.component.html',
  styleUrls: ['./fourohfour.component.scss']
})
export class FourohfourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
