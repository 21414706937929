<div class="app-carousel">
  <div class="carousel-list">

    <div class="carousel-list-item" [ngClass]="{ 'current-slide' : currentSlide === 1 }">
        <div class="body-section--1">
            <div class="body-left">
                <div class="body-left-row1">An object in <span>motion</span> stays in <span>motion</span></div>
                <div class="body-left-row2">
                    <div>Access our latest instructor lead Deltek & IBM certified training courses, virtual events, and specialized content in a self-driven and self-paced digital environment. Whether your team has years of experience, or they are just starting their role, we can help you be prepared for your next project while helping you advance your career by equipping you with the vital skills you need to succeed.</div>
                </div>
                <div class="body-left-row3">
                    <button mat-flat-button color="primary" routerLink="../learning/courses">browse courses</button>
                </div>
            </div>
            <div class="body-right">
                <img src="../../../assets/images/app-images/bulb-network.png" alt="Bulb Network" class="mono-image">
                <img src="../../../assets/images/app-images/bulb-network-yellow-L1.png" alt="Bulb Network in Color" class="color-image">
            </div>
        </div>
    </div>

    <div class="carousel-list-item" [ngClass]="{ 'current-slide' : currentSlide === 2 }">
      <div class="body-section--1">
        <div class="body-left">
          <div class="body-left-row1">A little <span>progress</span> each day adds up to big <span>results</span></div>
          <div class="body-left-row2">
              <div>Our certified instructor lead training courses help promote career advancement by equipping you with the vital skills you need to succeed. We offer flexible training taught by Deltek and IBM subject matter experts. Our self-driven, and self-paced courses help you keep up with today’s technologies while advancing your personal growth, knowledge, and career.</div>
          </div>
          <div class="body-left-row3">
              <button mat-flat-button color="primary" routerLink="../learning/courses">learning paths</button>
          </div>
        </div>
        <div class="body-right">
          <img src="../../../assets/images/app-images/journey.png" alt="Snail Race" class="mono-image">
          <img src="../../../assets/images/app-images/journey-color.png" alt="Snail Race in Color" class="color-image">
        </div>
      </div>
    </div>

    <div class="carousel-list-item" [ngClass]="{ 'current-slide' : currentSlide === 3 }">
      <div class="body-section--1">
        <div class="body-left">
          <div class="body-left-row1">Making it <span>stick</span> using repetitive <span>reinforcement</span></div>
          <div class="body-left-row2">
              <div>Our curriculum of application-focused Deltek and IBM certified courses taught by subject matter experts with firsthand product knowledge and a proven track record of success will ensure you’ll leave with the right skills to benefit your organization, career, and personal growth.</div>
          </div>
          <div class="body-left-row3">
              <button mat-flat-button color="primary" routerLink="../learning/courses">get started</button>
          </div>
        </div>
        <div class="body-right">
          <img src="../../../assets/images/app-images/person.png" alt="Business Person" class="mono-image">
          <img src="../../../assets/images/app-images/person-color.png" alt="Business Person in Color" class="color-image">
        </div>
      </div>
    </div>

  </div>
</div>

