import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { noop } from 'rxjs';
import { Video } from 'src/app/models/video';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  uri: string = 'https://learninginmotion.herokuapp.com/api/video';
  constructor(
    private http: HttpClient
  ) { }
  getVideos() {
    return this.http.get<Video[]>(`${this.uri}s`);
  }
  addVideo(video: Video) {
    return this.http.post<Video>(this.uri, video).toPromise();
  }
  updateVideo(id: string, video: Video) {
    return this.http.put(`${this.uri}/${id}`, video).toPromise();
  }
  deleteVideo(id: string) {
    return this.http.delete(`${this.uri}/${id}`).toPromise();
  }
  createVideoForm(current?: Video) {
    const vidForm =  new FormGroup({
      vimId: new FormControl(''),
      title: new FormControl(''),
      course: new FormControl(''),
      platform: new FormControl(''),
      version: new FormControl(''),
      instructor: new FormControl(''),
      keyTopics: new FormControl([]),
      desc: new FormControl(''),
      tags: new FormControl([]),
      updated: new FormControl(new Date())
    });
    current ? vidForm.patchValue(current) : noop();
    return vidForm;
  }
}
