<form [formGroup]="video" *ngIf="video" class="video-form">
    <mat-form-field appearance="fill" class="video-form-field">
        <mat-label class="video-form-field-title">Title</mat-label>
        <input matInput type="text" formControlName="title" class="video-form-field-input">
    </mat-form-field>
    <mat-form-field appearance="fill" class="video-form-field">
        <mat-label class="video-form-field-title">Vimeo ID</mat-label>
        <input matInput type="text" formControlName="vimId" class="video-form-field-input">
    </mat-form-field>
    <mat-form-field appearance="fill" class="video-form-field">
        <mat-label class="video-form-field-title">Course</mat-label>
        <mat-select formControlName="course" class="video-form-select">
            <mat-option *ngFor="let course of courses" [value]="course._id" class="video-form-select-option">{{ course.title }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="video-form-field">
        <mat-label class="video-form-field-title">Platform</mat-label>
        <mat-select formControlName="platform" class="video-form-select">
            <mat-option *ngFor="let platform of platforms" [value]="platform" class="video-form-select-option">{{ platform }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="video-form-field">
        <mat-label class="video-form-field-title">Version</mat-label>
        <input matInput type="text" formControlName="version" class="video-form-field-input">
    </mat-form-field>
    <mat-form-field appearance="fill" class="video-form-field">
        <mat-label class="video-form-field-title">Instructor</mat-label>
        <mat-select formControlName="instructor" class="video-form-select">
            <mat-option *ngFor="let instructor of instructors" [value]="instructor._id" class="video-form-select-option">{{ instructor.firstName }} {{ instructor.lastName }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="video-form-field">
        <mat-label class="video-form-field-title">Description</mat-label>
        <textarea matInput formControlName="desc" class="video-form-field-input"></textarea>
    </mat-form-field>

    <mat-form-field class="video-chip-list">
        <mat-label>Tags</mat-label>
        <mat-chip-list #chipListTags aria-label="Tags">
          <mat-chip
            *ngFor="let tag of tags"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeTag(tag)"
        > {{ tag }} <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="New tag..."
                 [matChipInputFor]="chipListTags"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addTag($event)">
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="video-chip-list">
        <mat-label>Key Topics</mat-label>
        <mat-chip-list #chipListKeyTopics aria-label="Key Topics">
          <mat-chip
            *ngFor="let keyTopic of keyTopics"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeKeyTopic(keyTopic)"
        > {{ keyTopic }} <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="New key topic..."
                 [matChipInputFor]="chipListKeyTopics"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addKeyTopic($event)">
        </mat-chip-list>
    </mat-form-field>

    <label for="fileInput">Video Thumbail Image</label>
    <input type="file" (change)="handleFileInput($event)" id="fileInput">
</form>

<button mat-button color="primary" (click)="submitVideo()">submit</button>
<button mat-button color="accent" (click)="onNoClick()">cancel</button>