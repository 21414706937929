<div class="login">
    <form [formGroup]="form" class="login-form">
        <img src="../../../assets/images/KLiM-logo.svg" alt="KLiM Logo" class="form-klim-logo">
        <h1>sign in</h1>
        <mat-form-field appearance="fill" class="login-form-field">
            <mat-label class="login-form-field-label">E-mail</mat-label>
            <input type="email" matInput formControlName="username" class="login-form-field-input">
        </mat-form-field>
        <mat-form-field appearance="fill" class="login-form-field">
            <mat-label class="login-form-field-label">Password</mat-label>
            <input type="password" matInput formControlName="password" class="login-form-field-input">
        </mat-form-field>
        <button mat-flat-button color="primary" type="submit" (click)="login(form)" class="login-form-button-submit">login</button>
        <a routerLink="/reset-password" class="login-form-reset">forgot password?</a>
        <div class="login-form-bottom">
            <span>don't have an account?</span>
            <a routerLink="/register" class="login-form-bottom-signup">Sign up</a>
        </div>
    </form>
</div>
