import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { noop, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { AdminCompanyFormComponent } from '../../components/admin-company-form/admin-company-form.component';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-admin-company',
  templateUrl: './admin-company.component.html',
  styleUrls: ['./admin-company.component.scss']
})
export class AdminCompanyComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  darkMode: boolean = false;
  companies$!: Observable<Company[]>;
  companyForm: FormGroup = new FormGroup({
    companyId: new FormControl(''),
    name: new FormControl(''),
    poc: new FormControl(''),
    address: new FormGroup({
        street: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        zip: new FormControl('')
    }),
    subscriptionId: new FormControl(''),
    users: new FormControl([])
  });
  constructor(
    private companyService: CompanyService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) { }
  getSettings() {
    this.sharedService.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data) => {
      this.darkMode = data;
    }, err => console.log(err), () => console.log('done'));
  }
  getCompanies() {
    this.companies$ = this.companyService.getCompanies();
  }
  crudCompany(action: string, company?: Company) {
    company ? console.log(company._id) : noop();
    company ? this.companyForm.patchValue(company) : noop();
    let dialog = this.dialog.open(AdminCompanyFormComponent, {
      backdropClass: 'instructor-dialog-background',
      panelClass: 'instructor-dialog-container',
      data: this.companyForm
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (action === 'post' && data) {
          this.postCompany(data.value);
        } else if (action === 'put' && company && data) {
          this.companyForm.patchValue(data.value);
          this.putCompany(company._id, this.companyForm.value);
        } else {
          this.companyForm.reset();
        }
      }, err => console.log(err), () => console.log('done'));
  }
  async postCompany(company: Company) {
    let poster = await this.companyService.addCompany(company);
    this.resetForm();
    this.getCompanies();
  }
  async deleteCompany(company: Company) {
    if (company._id) {
      let deleted = await this.companyService.deleteCompany(company._id);
    }
    this.getCompanies();
  }
  async putCompany(id: string, company: Company) {
    let putted = await this.companyService.updateCompany(id, company);
    this.resetForm();
    this.getCompanies();
  }
  resetForm() {
    this.companyForm.reset();
    // this.instructorForm.markAsPristine();
  }
  ngOnInit(): void {
    this.getSettings();
    this.getCompanies();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
