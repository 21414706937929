import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Account } from 'src/app/models/account';
import { Company } from 'src/app/models/company';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  unsubscribe$ = new Subject();
  form!: FormGroup;
  userForm!: FormGroup;
  companies$!: Observable<Company[]>;
  constructor(
    public authService: AuthService,
    private userService: UserService,
    private companyService: CompanyService,
    private router: Router
  ) {
    this.form = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required
      ]),
      name: new FormControl('', [
        Validators.required
      ]),
      company: new FormControl('', [
        Validators.required
      ])
    });
    this.userForm = this.userService.userForm();
  }
  getCompanies() {
    this.companies$ = this.companyService.getCompanies();
  }
  async registerAccount(): Promise<Account> {
    const acct = this.form.value;
    return await this.authService.register(acct.username, acct.password);
  }
  async addNewUser(): Promise<User> {
    const acct = await this.registerAccount();
    const user = this.userForm;
    const acctForm = this.form.value;
    user.patchValue({ userId: acct._id, email: acct.username, name: acctForm.name, company: acctForm.company });
    console.log(user.value);
    console.log(this.form.value);
    return await this.userService.postUser(user.value);
  }
  async addNew() {
    const added = await this.addNewUser()
    if (added._id) {
      this.router.navigate(['/login']);
    } else {
      alert('Something went wrong, please try again');
    }
  }
  ngOnInit(): void {
    this.getCompanies();
  }

}
