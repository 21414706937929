import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private uri: string = 'https://learninginmotion.herokuapp.com/api/company';
  private uris: string = 'https://learninginmotion.herokuapp.com/api/companies';
  constructor(
    private http: HttpClient
  ) { }
  getCompanies() {
    return this.http.get<Company[]>(this.uris);
  }
  addCompany(company: Company) {
    return this.http.post(this.uri, company).toPromise();
  }
  updateCompany(id: string, company: Company) {
    return this.http.put(`${this.uri}/${id}`, company).toPromise();
  }
  deleteCompany(id: string) {
    return this.http.delete(`${this.uri}/${id}`).toPromise();
  }
}
