import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Instructor } from 'src/app/models/instructor';
import { InstructorService } from 'src/app/services/instructor.service';
import { SharedService } from 'src/app/services/shared.service';
import { StorageService } from 'src/app/services/storage.service';
import { AdminInstructorFormComponent } from '../../components/admin-instructor-form/admin-instructor-form.component';

@Component({
  selector: 'app-admin-instructor',
  templateUrl: './admin-instructor.component.html',
  styleUrls: ['./admin-instructor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminInstructorComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject;
  darkMode: boolean = false;
  instructors$!: Instructor[];
  instructorForm: FormGroup = new FormGroup({
    instructorId: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    title: new FormControl(''),
    email: new FormControl(''),
    socials: new FormGroup({
      facebook: new FormControl(''),
      linkedin: new FormControl(''),
      twitter: new FormControl(''),
      instagram: new FormControl(''),
      youtube: new FormControl('')
    }),
    bio: new FormControl(''),
    courses: new FormControl([]),
    videos: new FormControl([]),
    tags: new FormControl([])
  });
  constructor(
    private instructorsService: InstructorService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private storageService: StorageService
  ) { }
  getSettings() {
    this.sharedService.darkMode.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data) => {
      this.darkMode = data;
    }, err => console.log(err), () => console.log('done'));
  }
  getInstructors() {
    this.instructorsService.getInstructors().pipe(takeUntil(this.unsubscribe$))
      .subscribe((instructors: Instructor[]) => {
        this.instructors$ = instructors;
      }, err => console.log(err), () => console.log('got instructors'));
  }
  crudInstructor(action: string, instructor?: Instructor) {
    let id: string;
    if (instructor) {
      id = instructor._id;
      this.instructorForm.patchValue(instructor);
    }
    let dialog = this.dialog.open(AdminInstructorFormComponent, {
      backdropClass: 'instructor-dialog-background',
      panelClass: 'instructor-dialog-container',
      data: this.instructorForm
    });

    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        const instructor: Instructor = data.instructor;
        const image: File = data.image;
        const file: Event = data.file;

        if (action === 'post' && data) {
          this.postInstructor(instructor, file);
        } else if (action === 'put' && instructor && data && data !== 'cancel') {
          this.instructorForm.patchValue(instructor);
          image ? this.putInstructor(id, this.instructorForm.value, file) : this.putInstructor(id, this.instructorForm.value);
        } else if (data === 'cancel') {
          this.instructorForm.reset();
        } else {
          this.instructorForm.reset();
        }

        // if (action === 'post' && data) {
        //   this.postInstructor(data.instructor, data.image);
        // } else if (action === 'put' && instructor && data && data !== 'cancel') {
        //   this.instructorForm.patchValue(data.instructor);
        //   data.image ? this.putInstructor(id, this.instructorForm.value, data.image) : this.putInstructor(id, this.instructorForm.value);
        // } else if (data === 'cancel') {
        //   this.instructorForm.reset();
        // } else {
        //   this.instructorForm.reset();
        // }
      }, err => console.log(err), () => console.log('done'));
  }
  async postInstructor(instructor: Instructor, file: Event) {
    let poster = await this.instructorsService.addInstructor(instructor);
    if (poster._id) {
      // this.postImage(poster._id, image);
      this.handleFileUpload(file, instructor.instructorId);
    }
    this.resetForm();
    this.getInstructors();
  }
  async deleteInstructor(instructor: Instructor) {
    if (instructor._id) {
      let deleted = await this.instructorsService.deleteInstructor(instructor._id);
      console.log(deleted);
    }
    this.getInstructors();
  }
  async putInstructor(id: string, instructor: Instructor, file?: Event) {
    let putted = await this.instructorsService.putInstructor(id, instructor);
    if (putted._id && file) {
      // this.postImage(putted._id, image);
      this.handleFileUpload(file, putted.instructorId);
    }
    this.resetForm();
    this.getInstructors();
  }
  handleFileUpload(event: any, id: string) {
    let fileToUpload;
    if (event.target.files) {
      fileToUpload = event.target.files[0];
      const ret = this.storageService.uploadFile(event, 'user', id);
      const fileName = event.target.files[0].name;
      const fileRef = ret.fileRef;
      const task = ret.task;
      task.snapshotChanges().subscribe();
    } else {
      return;
    }
  }
  resetForm() {
    this.instructorForm.reset();
    // this.instructorForm.markAsPristine();
  }
  ngOnInit(): void {
    this.getSettings();
    this.getInstructors();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
