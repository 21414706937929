import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noop, Observable } from 'rxjs';
import { Question } from 'src/app/models/question';
import { HelpService } from 'src/app/services/help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  questions$!: Observable<Question[]>;
  questionForm!: FormGroup;
  categories: string[] = [
    'bug or feature',
    'favorites',
    'how to',
    'logging in',
    'registering',
    'where is it'
  ];
  constructor(
    private helpService: HelpService
  ) {
    this.questionForm = new FormGroup({
      category: new FormControl('', [
        Validators.required
      ]),
      question: new FormControl('', [
        Validators.required
      ]),
      answer: new FormControl('')
    });
  }
  getQuestions() {
    this.questions$ = this.helpService.getQuestions();
  }
  async addQuestion(question: Question) {
    let posted = await this.helpService.addQuestion(question);
    posted ? this.getQuestions() : noop();
    this.questionForm.reset();
  }
  ngOnInit(): void {
    this.getQuestions();
  }

}
