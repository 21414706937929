
<div class="instructor-profile flex-container-column" *ngIf="profile">
    <div class="instructor-profile-row1">
        <div class="instructor-profile-row1-left">
            <div class="instructor-img-container">
                <img [src]="downloadUrl | async" alt="Instructor Image">
            </div>
        </div>
        <div class="instructor-profile-row1-right flex-spacer flex-container-column">
            <div class="profile-close"><i class="far fa-times-circle" (click)="onNoClick()"></i></div>
            <div class="profile-lastname">{{ profile.lastName }}</div>
            <div class="profile-firstname">{{ profile.firstName }}</div>
            <div class="profile-orgposition">{{ profile.title }}</div>
            <div class="profile-socials" *ngIf="profile.socials">
                <a href="{{ profile.socials.facebook }}" target="_blank"><i class="fab fa-facebook-f"></i></a>
                <a href="{{ profile.socials.instagram }}" target="_blank"><i class="fab fa-instagram"></i></a>
                <a href="{{ profile.socials.twitter }}" target="_blank"><i class="fab fa-twitter"></i></a>
                <a href="{{ profile.socials.linkedin }}" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                <a href="{{ profile.socials.youtube }}" target="_blank"><i class="fab fa-youtube"></i></a>
                <a href="mailto:{{ profile.email }}"><i class="fas fa-envelope"></i></a>
            </div>
        </div>
    </div>
    <div class="instructor-profile-row2 profile-bio">
        <h5 class="profile-bio-header">bio</h5>
        <p class="profile-bio-text" [ngClass]="{'profile-bio-text-expanded': showExpanded}">{{ profile.bio }}</p>
        <p class="profile-bio-expand" (click)="expand()">expand</p>
    </div>
    <div class="instructor-profile-row3 flex-container-row">
        <div class="profile-tags">
            <h5 class="profile-bio-header">tags</h5>
            <mat-chip-list aria-orientation="horizontal" selectable="false" *ngIf="profile.tags">
                <mat-basic-chip class="profile-tags-chips" *ngFor="let tag of profile.tags">{{ tag }}</mat-basic-chip>
            </mat-chip-list>
        </div>
        <div class="profile-courses">
            <h5 class="profile-bio-header">courses</h5>
            <mat-nav-list *ngIf="profile.courses">
                <a mat-list-item href="/course/{{ course._id }}" *ngFor="let course of profile.courses">
                    {{ course.platform }} - {{ course.title }}
                </a>
            </mat-nav-list>
        </div>
    </div>
</div>